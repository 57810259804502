// Import libraries.
import React from "react";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";

// Import components.
import { Button as MuiButton } from "@mui/material";

interface OWN_PROPS {
    id?: string;
    type?: "neutral" | "primary" | "secondary" | "semantic-positive-primary" | "semantic-positive-secondary" | "semantic-negative-primary" | "semantic-negative-secondary";
    className?: string;
    style?: React.CSSProperties;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    swallowClickEvents?: boolean;
}
interface PROPS extends OWN_PROPS, Omit<React.ComponentPropsWithoutRef<typeof MuiButton>, keyof OWN_PROPS> {}

const Button = React.forwardRef((props: PROPS, ref: React.ForwardedRef<HTMLElement>) => {
    const { id, type = "primary", className, style, startIcon, endIcon, disabled, onClick, swallowClickEvents = true, ...otherProps } = props;

    const { classes } = useStyles(props);

    return (
        <MuiButton
            id={id ? "button-" + id : undefined}
            ref={ref as React.RefObject<HTMLLabelElement>}
            component={"label" as React.ElementType}
            className={classnames(classes.root, classes[type], { [classes.noChildren]: props.children == null }, className)}
            style={style}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={"outlined"}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
                if (onClick && !disabled) {
                    if (swallowClickEvents) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    if ((event as any).code != null) {
                        const eventAsKeyboardEvent = event as unknown as React.KeyboardEvent;

                        const modifierPresent = eventAsKeyboardEvent.ctrlKey || eventAsKeyboardEvent.altKey || eventAsKeyboardEvent.shiftKey || eventAsKeyboardEvent.metaKey;

                        // Triggered as a result of keyboard event.
                        if (!modifierPresent && ["Space", "Enter"].includes(eventAsKeyboardEvent.code)) {
                            onClick(event);
                        }
                    } else {
                        if ((event as any).button != null) {
                            // Triggered as a result of mouse event.
                            onClick(event);
                        }
                    }
                }
            }}
            {...otherProps}
        />
    );
});

const useStyles = makeStyles<OWN_PROPS>({ name: "Button" })((_theme, _props) => ({
    root: {
        flex: "0 0 auto",
        display: "flex",
        pointerEvents: "all",
        backgroundColor: "inherit",
        color: "inherit",
        borderColor: "inherit",
        "&.Mui-disabled": {
            pointerEvents: "all !important" as "all",
            "&:hover": {
                cursor: "not-allowed",
            },
        },
    },
    noChildren: {
        "& > .MuiButton-startIcon, & > .MuiButton-endIcon": {
            marginLeft: 0,
            marginRight: 0,
        },
    },

    neutral: {
        backgroundColor: "var(--button-neutral-background-color)",
        color: "var(--button-neutral-color)",
        borderColor: "var(--button-neutral-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-neutral-hover-background-color)",
            color: "var(--button-neutral-hover-color)",
            borderColor: "var(--button-neutral-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-neutral-disabled-background-color)",
            color: "var(--button-neutral-disabled-color)",
            borderColor: "var(--button-neutral-disabled-border-color)",
        },
    },
    primary: {
        backgroundColor: "var(--button-primary-background-color)",
        color: "var(--button-primary-color)",
        borderColor: "var(--button-primary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-primary-hover-background-color)",
            color: "var(--button-primary-hover-color)",
            borderColor: "var(--button-primary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-primary-disabled-background-color)",
            color: "var(--button-primary-disabled-color)",
            borderColor: "var(--button-primary-disabled-border-color)",
        },
    },
    secondary: {
        backgroundColor: "var(--button-secondary-background-color)",
        color: "var(--button-secondary-color)",
        borderColor: "var(--button-secondary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-secondary-hover-background-color)",
            color: "var(--button-secondary-hover-color)",
            borderColor: "var(--button-secondary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-secondary-disabled-background-color)",
            color: "var(--button-secondary-disabled-color)",
            borderColor: "var(--button-secondary-disabled-border-color)",
        },
    },
    "semantic-positive-primary": {
        backgroundColor: "var(--button-semantic-positive-primary-background-color)",
        color: "var(--button-semantic-positive-primary-color)",
        borderColor: "var(--button-semantic-positive-primary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-semantic-positive-primary-hover-background-color)",
            color: "var(--button-semantic-positive-primary-hover-color)",
            borderColor: "var(--button-semantic-positive-primary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-semantic-positive-primary-disabled-background-color)",
            color: "var(--button-semantic-positive-primary-disabled-color)",
            borderColor: "var(--button-semantic-positive-primary-disabled-border-color)",
        },
    },
    "semantic-positive-secondary": {
        backgroundColor: "var(--button-semantic-positive-secondary-background-color)",
        color: "var(--button-semantic-positive-secondary-color)",
        borderColor: "var(--button-semantic-positive-secondary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-semantic-positive-secondary-hover-background-color)",
            color: "var(--button-semantic-positive-secondary-hover-color)",
            borderColor: "var(--button-semantic-positive-secondary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-semantic-positive-secondary-disabled-background-color)",
            color: "var(--button-semantic-positive-secondary-disabled-color)",
            borderColor: "var(--button-semantic-positive-secondary-disabled-border-color)",
        },
    },
    "semantic-negative-primary": {
        backgroundColor: "var(--button-semantic-negative-primary-background-color)",
        color: "var(--button-semantic-negative-primary-color)",
        borderColor: "var(--button-semantic-negative-primary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-semantic-negative-primary-hover-background-color)",
            color: "var(--button-semantic-negative-primary-hover-color)",
            borderColor: "var(--button-semantic-negative-primary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-semantic-negative-primary-disabled-background-color)",
            color: "var(--button-semantic-negative-primary-disabled-color)",
            borderColor: "var(--button-semantic-negative-primary-disabled-border-color)",
        },
    },
    "semantic-negative-secondary": {
        backgroundColor: "var(--button-semantic-negative-secondary-background-color)",
        color: "var(--button-semantic-negative-secondary-color)",
        borderColor: "var(--button-semantic-negative-secondary-border-color)",
        "&.MuiButton-outlined:not(.Mui-disabled):hover": {
            backgroundColor: "var(--button-semantic-negative-secondary-hover-background-color)",
            color: "var(--button-semantic-negative-secondary-hover-color)",
            borderColor: "var(--button-semantic-negative-secondary-hover-border-color)",
        },
        "&.MuiButton-outlined.Mui-disabled": {
            backgroundColor: "var(--button-semantic-negative-secondary-disabled-background-color)",
            color: "var(--button-semantic-negative-secondary-disabled-color)",
            borderColor: "var(--button-semantic-negative-secondary-disabled-border-color)",
        },
    },
}));

export default Button;
