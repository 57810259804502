// Import libraries.
import React from "react";
import { makeStyles } from "tss-react/mui";
import classnames from "classnames";

// Import components.
import { IconButton as MuiIconButton } from "@mui/material";

interface OWN_PROPS {
    id?: string;
    type?: "neutral" | "default" | "semantic-positive" | "semantic-negative";
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    swallowClickEvents?: boolean;
}
interface PROPS extends OWN_PROPS, Omit<React.ComponentPropsWithoutRef<typeof MuiIconButton>, keyof OWN_PROPS> {}

const IconButton = React.forwardRef((props: PROPS, ref: React.ForwardedRef<HTMLElement>) => {
    const { id, type = "default", className, style, disabled, onClick, swallowClickEvents = true, ...otherProps } = props;

    const { classes } = useStyles(props);

    return (
        <MuiIconButton
            id={id ? "icon-button-" + id : undefined}
            ref={ref as React.RefObject<HTMLLabelElement>}
            component={"label" as React.ElementType}
            className={classnames(classes.root, classes[type], className)}
            style={style}
            disabled={disabled}
            onClick={(event: React.MouseEvent<HTMLLabelElement>) => {
                if (onClick && !disabled) {
                    if (swallowClickEvents) {
                        event.preventDefault();
                        event.stopPropagation();
                    }

                    if ((event as any).code != null) {
                        const eventAsKeyboardEvent = event as unknown as React.KeyboardEvent;

                        const modifierPresent = eventAsKeyboardEvent.ctrlKey || eventAsKeyboardEvent.altKey || eventAsKeyboardEvent.shiftKey || eventAsKeyboardEvent.metaKey;

                        // Triggered as a result of keyboard event.
                        if (!modifierPresent && ["Space", "Enter"].includes(eventAsKeyboardEvent.code)) {
                            onClick(event);
                        }
                    } else {
                        if ((event as any).button != null) {
                            // Triggered as a result of mouse event.
                            onClick(event);
                        }
                    }
                }
            }}
            {...otherProps}
        />
    );
});

const useStyles = makeStyles<OWN_PROPS>({ name: "IconButton" })((_theme, _props) => ({
    root: {
        flex: "0 0 auto",
        display: "flex",
        pointerEvents: "all",
        backgroundColor: "transparent",
        color: "inherit",
        borderColor: "transparent",
        "&.Mui-disabled": {
            pointerEvents: "all !important" as "all",
            color: "currentColor",
            opacity: 0.5,
            "&:hover": {
                cursor: "not-allowed",
            },
        },
        "&:hover > .MuiTouchRipple-root": {
            backgroundColor: "currentColor",
            opacity: 0.2,
        },
    },
    neutral: {
        color: "inherit",
    },
    default: {
        color: "var(--icon-button-default-color)",
    },
    "semantic-positive": {
        color: "var(--icon-button-semantic-positive-color)",
    },
    "semantic-negative": {
        color: "var(--icon-button-semantic-negative-color)",
    },
}));

export default IconButton;
