import { I18n } from "@lingui/core";

import availableCurrencies from "assets/data/availableCurrencies";
import StringUtils from "utils/String";

export interface CurrencyInfo {
    code: string;
    label: React.ReactNode;
    decimalDigits: number;
    decimalSeparator: string;
    labelAsString: (i18n: I18n) => string | undefined;
    name: string;
    spaceBetweenAmountAndSymbol: boolean;
    symbol: string;
    symbolOnLeft: boolean;
    thousandsSeparator: string;
}

export const getCurrencyInfobyCode = (code: string): CurrencyInfo | null => {
    if (StringUtils.isNullOrEmpty(code)) {
        return null;
    }

    const currency = availableCurrencies.find((item: any) => item.code === code);

    return currency || null;
};
