import { SampleDataAction } from "store/actions/sampleData";
import SampleData from "types/common/SampleData";

const initialState: SampleData = {
    showSampleData: false,
    showSwitch: false,
};

const reducer = (state: SampleData = initialState, action: SampleDataAction) => {
    let newState = state;

    switch (action.type) {
        case "SET_SAMPLE_DATA":
            newState = action.payload;

            break;
        default:
        // Do nothing.
    }

    return newState;
};

export default reducer;
