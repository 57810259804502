// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import { toast } from "utils/Toast";
import { Divider, Theme } from "@mui/material";

import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// Import types.
import AppInfo from "types/models/AppInfo";
import BillingPlan, { FeaturesList } from "types/models/BillingPlan";
import { RadioButtonFieldOptions } from "components/common/form/fields/RadioButtonField";
import { LabelledRadioOption } from "types/common/LabelledRadioOption";
import Session from "types/common/Session";
import PortalState from "types/store";

// Import components.
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import CustomDialog from "components/common/dialog/CustomDialog";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";
import Tabs, { TabConfig } from "components/common/tabs";
import Tooltip from "components/common/Tooltip";

// Import services.
import Services from "../services";

// Import utilities.
import NumberFormatter from "utils/formatters/Number";
import PublishState from "types/enums/PublishState";

// Import icons.
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import BoltIcon from "@mui/icons-material/Bolt";
import ProblemIcon from "@mui/icons-material/ReportProblemRounded";

enum PlanType {
    BASIC = "basic-plans",
    PLUS = "plus-plans",
    BULK = "bulk-plans",
}

interface OWN_PROPS {
    context: "super" | "team";
    appInfo: AppInfo;
    onClose: (submitted: boolean) => void;
}

interface STATE_PROPS {
    session: Session;
}
interface DISPATCH_PROPS {
    populateAvailableApps: () => void;
}

interface PROPS extends OWN_PROPS, STATE_PROPS, DISPATCH_PROPS, WithStyles<typeof styles> {}

interface STATE {
    billingPlans: BillingPlan[];
    originalPlanCode: string | null;
    currentPlanCode: string | null;
    activeTabIndex: number;
    isBusy: boolean;
    highlight: boolean;
    allowBasicPlans: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        populateAvailableApps: () => dispatch({ type: "app.populateAvailableApps" }),
    };
};

class ChooseBillingPlanDialog extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        billingPlans: [],
        originalPlanCode: null,
        currentPlanCode: null,
        activeTabIndex: 0,
        isBusy: false,
        highlight: false,
        allowBasicPlans: false,
    };

    private timeout: number = 0;
    private interval: number = 0;

    async componentDidMount() {
        const { appInfo } = this.props;

        try {
            this.setState({ isBusy: true });

            const [allBillingPlans, originalPlanCode, appPlanStatus] = await Promise.all([Services.getBillingPlans(), Services.getCurrentBillingPlanCode(appInfo.appId), Services.checkAppPlanStatus(appInfo.appId)]);

            const billingPlans = allBillingPlans.filter((plan) => plan.generation !== 1);

            // Find the current plan
            const currentPlan = billingPlans.find((item) => item.planCode === appInfo.billing_plan?.planCode);

            // Determine the active plan type
            let activePlanType = PlanType.BASIC;
            if (currentPlan?.isBulkPlan === 1) {
                activePlanType = PlanType.BULK;
            } else if (currentPlan?.isPlusPlan === 1) {
                activePlanType = PlanType.PLUS;
            }

            // Get the tags config
            const plansTagConfig = this.getTagsConfig(billingPlans);

            // Find active tab index
            const activeTabIndex = plansTagConfig.findIndex((tab) => tab.id === activePlanType);

            this.setState({ billingPlans, originalPlanCode, currentPlanCode: originalPlanCode, allowBasicPlans: appPlanStatus.allowBasicPlan, activeTabIndex: activeTabIndex !== -1 ? activeTabIndex : 0, isBusy: false });
        } catch (error: any) {
            toast.error(error);

            this.setState({ isBusy: false });
        }
    }

    componentDidUpdate(prevProps: Readonly<PROPS>, prevState: Readonly<STATE>): void {
        const { billingPlans, currentPlanCode } = this.state;
        if (prevState.currentPlanCode && currentPlanCode && prevState.currentPlanCode !== currentPlanCode) {
            const prevPlan = billingPlans.find((item) => item.planCode === prevState.currentPlanCode);
            const currentPlan = billingPlans.find((item) => item.planCode === currentPlanCode);
            if (prevPlan && currentPlan && prevPlan["baseApiIncluded"] !== currentPlan["baseApiIncluded"]) {
                this.setHighlight();
            }
        }
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout);
        window.clearInterval(this.interval);
    }

    setHighlight = () => {
        let count = 0;

        // Clear any existing intervals
        window.clearInterval(this.interval);
        window.clearTimeout(this.timeout);

        // Start flashing effect
        this.interval = window.setInterval(() => {
            this.setState((prevState) => ({ highlight: !prevState.highlight }));
            count++;

            if (count >= 3) {
                window.clearInterval(this.interval);
                this.setState({ highlight: false });
            }
        }, 333); // Toggle every 333ms for a flashing effect
    };

    handleTabChange = (index: number) => {
        const { activeTabIndex, currentPlanCode, originalPlanCode } = this.state;

        let updatedPlan: string | null = null;
        if (currentPlanCode && ((index === 0 && activeTabIndex === 1) || (activeTabIndex === 0 && index === 1))) {
            updatedPlan = currentPlanCode === "bcdevplus2" ? null : this.getPlanVariant(currentPlanCode);
        }

        if (!updatedPlan && index === 0 && !originalPlanCode?.includes("plus") && !originalPlanCode?.includes("bulk")) {
            updatedPlan = originalPlanCode;
        }

        if (!updatedPlan && index === 1 && originalPlanCode?.includes("plus") && !originalPlanCode.includes("bulk")) {
            updatedPlan = originalPlanCode;
        }

        if (!updatedPlan && index === 2 && originalPlanCode?.includes("bulk")) {
            updatedPlan = originalPlanCode;
        }

        this.setState({ activeTabIndex: index, currentPlanCode: updatedPlan }, () => {
            window.clearTimeout(this.timeout);
            window.clearInterval(this.interval);
        });
    };

    getPlanVariant = (currentPlanCode: string): string | null => {
        const planMapping: Record<string, string> = {
            bcdev2: "bcdevplus2",
            bcdevplus2: "bcdev2",
            bclite2: "bcliteplus2",
            bcliteplus2: "bclite2",
            bcstandard2: "bcstandardplus2",
            bcstandardplus2: "bcstandard2",
            bcbusiness2: "bcbusinessplus2",
            bcbusinessplus2: "bcbusiness2",
        };

        return planMapping[currentPlanCode] || null; // Return null if no mapping is found
    };

    handleDismiss = () => {
        this.props.onClose(false);
    };

    handleConfirm = async () => {
        const { appInfo, session } = this.props;
        const { billingPlans, currentPlanCode } = this.state;

        const billingPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        if (!billingPlan) return;

        this.setState({ isBusy: true });

        try {
            if (!session.isSuper && appInfo.publishState !== PublishState.LIVE && billingPlan.isLive) {
                await Services.setBillingPlanLive(appInfo.appId, billingPlan.planCode);
            } else {
                await Services.swapBillingPlan(appInfo.appId, billingPlan.planCode);
            }

            toast.success(<Trans>Successfully Swapped Plan!</Trans>);

            this.setState({ isBusy: false }, () => {
                this.props.populateAvailableApps();
                this.props.onClose(true);
            });
        } catch (error: any) {
            toast.error(error);

            this.setState({ isBusy: false });
        }
    };

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "planCode":
                this.setState({ currentPlanCode: value });

                break;
            default:
            // Do nothing.
        }
    };

    isFormValid = () => {
        const { billingPlans, currentPlanCode } = this.state;

        const billingPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        if (!billingPlan) return false;

        return true;
    };

    hasUnsavedChanges = () => {
        return this.state.currentPlanCode !== this.state.originalPlanCode;
    };

    getBillingPlansOptions = (billingPlans: BillingPlan[], type: PlanType) => {
        const { classes, context, appInfo } = this.props;
        const { currentPlanCode, allowBasicPlans } = this.state;

        const isBasicPlanRestricted = !allowBasicPlans && type === PlanType.BASIC;

        const isSelectable = (plan: BillingPlan) => {
            if (context === "super") return plan.isSuperSelectable;
            if (context === "team") {
                if (plan.planCode === "bcdev2") return type === PlanType.BASIC && appInfo.publishState !== PublishState.LIVE;
                return appInfo.publishState === PublishState.LIVE ? plan.isLive === true && plan.isUserSelectable : plan.isUserSelectable;
            }
            return false;
        };

        // Define plan type filters
        const typeFilters: Record<PlanType, (plan: BillingPlan) => boolean> = {
            [PlanType.BASIC]: (plan) => !plan.isPlusPlan && !plan.isBulkPlan && isSelectable(plan),
            [PlanType.PLUS]: (plan) => plan.isPlusPlan === 1 && !plan.isBulkPlan && isSelectable(plan),
            [PlanType.BULK]: (plan) => plan.isBulkPlan === 1 && isSelectable(plan),
        };

        // Filter and map billing plans
        return billingPlans
            .filter((plan) => typeFilters[type]?.(plan))
            .sort((a, b) => {
                if (context === "super" && type === PlanType.PLUS) {
                    // For BULK plans, sort by basePrice, pushing plans with basePrice === 0 to the bottom
                    return a.basePrice === 0 ? 1 : b.basePrice === 0 ? -1 : a.basePrice - b.basePrice;
                }
                return a.basePrice - b.basePrice;
            })
            .map((plan) => ({
                value: plan.planCode,
                label: (
                    <div key={plan.planCode} style={{ display: "flex", flexDirection: "column" }}>
                        <div className={classes.label}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant={"h6"} style={{ fontSize: "1em" }}>
                                    {plan.name}
                                </Typography>
                                {type === PlanType.PLUS && !plan.isUserSelectable && plan.isSuperSelectable && (
                                    <Tooltip alwaysShow arrow title={<Trans>Special Plan</Trans>}>
                                        <BoltIcon style={{ color: "var(--portal-color-yellow, inherit)" }} />
                                    </Tooltip>
                                )}
                                {isBasicPlanRestricted && <ProblemIcon style={{ color: "var(--portal-color-yellow, inherit)", marginLeft: ".25em" }} />}
                            </div>
                            <div style={{ display: "flex", alignItems: "baseline" }}>
                                <Typography variant={"h6"}>{plan.basePrice > 0 ? <>${NumberFormatter.formatInteger(plan.basePrice)}</> : <>{plan.planCode === "bcnomin2" ? <>$0</> : <Trans>Free</Trans>}</>}</Typography>
                                <Typography>
                                    /<Trans>month + usage</Trans>
                                </Typography>
                            </div>
                        </div>
                        {plan.planCode === currentPlanCode && <div className={classes.planDetailSmall}>{this.getFeaturesList(plan)}</div>}
                    </div>
                ),
                disabled: plan.planCode === "bcdev2" && context !== "super",
            })) as LabelledRadioOption[];
    };

    getFeaturesList = (selectedPlan: BillingPlan) => {
        const { highlight } = this.state;
        const { classes } = this.props;

        const featureList: FeaturesList[] = [
            { key: "coreFeatures", label: <Trans>Core Features</Trans> },
            { key: "includesEnterprise", label: <Trans>Enterprise Features</Trans> },
            { key: "includesRTT", label: <Trans>Real-time Tech (RTT)</Trans> },
            { key: "includesDeepData", label: <Trans>Includes Deep Data</Trans> },
            { key: "includesHosting", label: <Trans>Custom App Servers</Trans> },
            { key: "maxDau", label: <Trans>Daily Active Users</Trans> },
            { key: "maxAccounts", label: <Trans>Accounts</Trans> },
            { key: "baseApiIncluded", label: <Trans>Free API Calls</Trans> },
        ];

        return (
            <div className={classes.featureDetail}>
                {featureList.map((feature: FeaturesList, idx) => {
                    const { key, label } = feature;
                    return (
                        <span key={idx}>
                            {key === "maxDau" && <Divider style={{ margin: "0.3125em" }} />}

                            <div style={{ display: "flex", alignItems: "center", gap: "0.25em", flexWrap: "wrap" }}>
                                {key === "coreFeatures" || selectedPlan[key] ? <CheckCircleIcon style={{ color: "var(--label-positive-color, inherit)" }} /> : <BlockIcon style={{ color: "var(--label-negative-color, inherit)" }} />}

                                {key !== "coreFeatures" && typeof selectedPlan[key] === "number" && (
                                    <Typography className={key === "baseApiIncluded" && highlight ? classes.highlight : ""}>{Number(selectedPlan[key]) > 0 ? this.convertNumber(selectedPlan, key) : <Trans>Unlimited</Trans>}</Typography>
                                )}

                                <Typography noWrap>{label}</Typography>

                                {selectedPlan.bulkSavings > 0 && key === "baseApiIncluded" && (
                                    <Typography noWrap style={{ color: "var(--portal-color-blue, inherit)" }}>
                                        (<Trans>Save $</Trans>
                                        {NumberFormatter.formatInteger(selectedPlan.bulkSavings)})
                                    </Typography>
                                )}
                            </div>
                        </span>
                    );
                })}

                {selectedPlan.isBulkPlan === 1 && (
                    <div style={{ display: "flex", alignItems: "center", gap: "0.25em" }}>
                        <CheckCircleIcon style={{ color: "var(--label-positive-color, inherit)" }} />
                        <Typography noWrap>
                            <Trans>Additional usage discounts</Trans>
                        </Typography>
                    </div>
                )}
            </div>
        );
    };

    getTagsConfig = (billingPlans: BillingPlan[]) => {
        const { classes } = this.props;
        const { currentPlanCode, isBusy } = this.state;

        // Define plan type ids and their corresponding labels
        const planTypes = [
            { id: PlanType.BASIC, label: <Trans>Basic Plans</Trans> },
            { id: PlanType.PLUS, label: <Trans>Plus Plans</Trans> },
            { id: PlanType.BULK, label: <Trans>Bulk Plans</Trans> },
        ];

        const configs: TabConfig[] = [];

        planTypes.forEach(({ id, label }) => {
            const planOptions = this.getBillingPlansOptions(billingPlans, id);

            if (planOptions.length < 1) return; // Skip if no plans exist

            configs.push({
                id,
                label,
                component: (
                    <div style={{ display: "flex", flex: "1 1 auto" }}>
                        <FieldWrapper
                            name={"planCode"}
                            type={"radiobutton"}
                            value={currentPlanCode}
                            className={classes.field}
                            controlStyle={{ height: "unset" }}
                            onChange={this.handleChange}
                            disabled={isBusy}
                            options={
                                {
                                    optionsDirection: "column",
                                    radioAlignment: "flex-start",
                                    contained: true,
                                    multiline: true,
                                    options: planOptions, // Use stored value
                                } as RadioButtonFieldOptions
                            }
                        />
                    </div>
                ),
            });
        });

        return configs;
    };

    convertNumber = (selectedPlan: BillingPlan, key: "includesEnterprise" | "includesRTT" | "includesHosting" | "maxDau" | "maxAccounts" | "baseApiIncluded" | "includesDeepData") => {
        if (key === "baseApiIncluded") {
            return Number(selectedPlan[key]) >= 1000 ? (
                <b>
                    {NumberFormatter.formatInteger(Number(selectedPlan[key]) / 1000)} <Trans>Billion</Trans>
                </b>
            ) : (
                <b>{NumberFormatter.formatInteger(selectedPlan[key])}M</b>
            );
        }
        return <>{selectedPlan[key]}</>;
    };

    render() {
        const { appInfo, classes } = this.props;
        const { billingPlans, isBusy, activeTabIndex, currentPlanCode, allowBasicPlans } = this.state;

        const currentPlan = billingPlans.find((item) => item.planCode === appInfo.billing_plan?.planCode);
        const selectedPlan = billingPlans.find((item) => item.planCode === currentPlanCode);

        const isBasicPlanRestricted = !allowBasicPlans && activeTabIndex === 0;

        return (
            <CustomDialog
                id={"choose-billing-plan"}
                open={true}
                ready={!isBusy}
                onClose={this.handleDismiss}
                header={<Trans>Choose Billing Plan - {appInfo.appName}</Trans>}
                content={
                    <>
                        <div style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", padding: "0.3125em", marginBottom: "0.625em" }}>
                            {currentPlan && (
                                <Typography>
                                    <Trans>
                                        You are currently subscribed to <b>{currentPlan.name}</b>.
                                    </Trans>
                                </Typography>
                            )}

                            <Typography>
                                <Trans>Select the plan that suits you now. Upgrade anytime, as your audience grows.</Trans>
                            </Typography>
                        </div>

                        <div style={{ flex: "1 1 auto", display: "flex", flexDirection: "row", borderColor: "inherit" }}>
                            <Tabs orientation={"horizontal"} className={classes.tabs} configs={this.getTagsConfig(billingPlans)} value={activeTabIndex} onTabChanged={this.handleTabChange} />

                            <Divider orientation="vertical" flexItem className={classes.divider} />

                            <div className={classes.planDetail}>
                                {selectedPlan ? (
                                    <>
                                        <Typography noWrap>
                                            <Trans>
                                                Selected Plan: <b>{selectedPlan?.name}</b>
                                            </Trans>
                                        </Typography>

                                        <Typography noWrap>
                                            <Trans>Features:</Trans>
                                        </Typography>

                                        {selectedPlan && this.getFeaturesList(selectedPlan)}
                                    </>
                                ) : (
                                    <Typography style={{ margin: "auto" }}>
                                        <Trans>Please select a plan to see its features.</Trans>
                                    </Typography>
                                )}
                            </div>
                        </div>

                        {isBasicPlanRestricted && (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: ".3125em", padding: "0.3125em" }}>
                                <ProblemIcon style={{ color: "var(--portal-color-yellow, inherit)" }} />
                                <Typography>
                                    <Trans>
                                        Warning Basic Plans are not available because your app has Plus Features Enabled.
                                        <Link data-id={"learn-more"} href={"https://help.getbraincloud.com/en/articles/10722031-how-to-downgrade-an-app-from-a-plus-or-higher-plan-to-a-basic-plan"} target={"_blank"} style={{ marginLeft: ".3125em" }}>
                                            <Trans>Learn more...</Trans>
                                        </Link>
                                    </Trans>
                                </Typography>
                            </div>
                        )}

                        {!isBasicPlanRestricted && currentPlan && (
                            <Typography style={{ padding: "0.3125em", textAlign: "center", opacity: selectedPlan && currentPlan !== selectedPlan ? 1 : 0 }}>
                                <Trans>
                                    To change from your current plan ({currentPlan?.name}) to <b>{selectedPlan?.name}</b>, click <b>Confirm</b>.
                                </Trans>
                            </Typography>
                        )}

                        {appInfo.publishState !== PublishState.LIVE && (
                            <Typography style={{ textAlign: "center", color: "var(--portal-color-grey, inherit)" }}>
                                <Trans>Once you go live, you will not be able to return to Development-grade plans.</Trans>
                            </Typography>
                        )}
                    </>
                }
                actions={
                    <>
                        <Link data-id={"learn-more"} href={"http://getbraincloud.com/pricing"} target={"_blank"} style={{ marginLeft: "1.25em" }}>
                            <Trans>Learn more about brainCloud plans!</Trans>
                        </Link>

                        <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                            <Button id={"cancel"} type={"neutral"} onClick={this.handleDismiss} disabled={isBusy}>
                                {this.hasUnsavedChanges() ? <Trans>Cancel</Trans> : <Trans>Close</Trans>}
                            </Button>

                            <Button id={"confirm"} type={"primary"} onClick={this.handleConfirm} disabled={isBusy || !this.isFormValid() || !this.hasUnsavedChanges() || isBasicPlanRestricted}>
                                <Trans>Confirm</Trans>
                            </Button>
                        </div>
                    </>
                }
            />
        );
    }
}
const styles = (theme: Theme) =>
    createStyles({
        field: {
            flex: "0 0 auto",
            alignItems: "flex-start !important",
            padding: "2em",
            "& .MuiFormGroup-root": {
                gap: "1em",
            },
            "& label": {
                width: "25em !important",
            },
            [theme.breakpoints.down("lg")]: {
                padding: ".3125em",
                flex: "1 1 auto",
                "& label": {
                    width: "auto !important",
                },
                "& .MuiFormGroup-root": {
                    gap: ".5em",
                },
            },
        },
        tabs: {
            flex: "1 1 auto",
            maxWidth: "30em",
            height: "22em",
            overflow: "visible",
            [theme.breakpoints.down("lg")]: { maxWidth: "unset", height: "unset", overflow: "auto" },
        },
        planDetail: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            gap: ".5em",
            [theme.breakpoints.down("lg")]: { display: "none" },
        },
        planDetailSmall: {
            display: "none",
            [theme.breakpoints.down("lg")]: { display: "block" },
        },
        featureDetail: {
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            padding: "0 1em",
            gap: "0.25em",
            [theme.breakpoints.down("lg")]: { padding: "0 0 1em 0" },
        },
        divider: {
            margin: "0.3125em .5em",
            backgroundColor: "var(--primary-border-color, inherit)",

            [theme.breakpoints.down("lg")]: { display: "none" },
        },
        highlight: {
            color: "var(--portal-color-blue, inherit)",
            transition: "color 0.2s ease",
        },
        label: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            color: "var(--primary-color, inherit)",
            flexWrap: "wrap",
            maxHeight: "fit-content !important",
        },
    });
export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChooseBillingPlanDialog));
