import React from "react";
import { Trans } from "@lingui/macro";

import { Typography } from "@mui/material";
import Button from "../button/Button";

import ClipboardUtils from "utils/Clipboard";

interface OWN_PROPS {
    fallback?: React.ReactNode;
}
interface PROPS extends React.PropsWithChildren<OWN_PROPS> {}

interface STATE {
    hasError: boolean;
    error: string | null;
}

class ErrorBoundary extends React.Component<PROPS, STATE> {
    state: Readonly<STATE> = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(_error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({ error: "" + error.stack });
    }

    handleReloadComponent = () => {
        this.setState({ hasError: false, error: null });
    };

    handleCopyToClipboard = () => {
        const { hasError, error } = this.state;

        if (hasError) {
            ClipboardUtils.writeText(error);
        }
    };

    render() {
        const { fallback, children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <div style={{ margin: "auto" }}>
                    {fallback && fallback}

                    {!fallback && (
                        <Typography>
                            <Trans>Component Encountered An Unexpected Error.</Trans>
                        </Typography>
                    )}

                    <Button id={"reload-screen"} style={{ marginTop: "0.625em" }} onClick={this.handleReloadComponent}>
                        <Typography>
                            <Trans>Reload Component</Trans>
                        </Typography>
                    </Button>

                    <Button id={"copy-to-clipboard"} style={{ marginTop: "0.625em" }} onClick={this.handleCopyToClipboard}>
                        <Typography>
                            <Trans>Copy Error To Clipboard</Trans>
                        </Typography>
                    </Button>
                </div>
            );
        } else {
            return children;
        }
    }
}

export default ErrorBoundary;
