// Import libraries.
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

const availableCurrencies = [
    {
        code: "USD",
        label: <Trans>US Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`US Dollar`),
        name: "US Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "EUR",
        label: <Trans>Euro</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Euro`),
        name: "Euro",
        spaceBetweenAmountAndSymbol: true,
        symbol: "€",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "AED",
        label: <Trans>UAE Dirham</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`UAE Dirham`),
        name: "UAE Dirham",
        spaceBetweenAmountAndSymbol: true,
        symbol: "د.إ",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "AFN",
        label: <Trans>Afghani</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Afghani`),
        name: "Afghani",
        spaceBetweenAmountAndSymbol: false,
        symbol: "؋",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "ALL",
        label: <Trans>Lek</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Lek`),
        name: "Lek",
        spaceBetweenAmountAndSymbol: false,
        symbol: "L",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "AMD",
        label: <Trans>Armenian Dram</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Armenian Dram`),
        name: "Armenian Dram",
        spaceBetweenAmountAndSymbol: true,
        symbol: "֏",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "ANG",
        label: <Trans>Netherlands Antillean Guilder</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Netherlands Antillean Guilder`),
        name: "Netherlands Antillean Guilder",
        spaceBetweenAmountAndSymbol: false,
        symbol: "ƒ",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "AOA",
        label: <Trans>Kwanza</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Kwanza`),
        name: "Kwanza",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Kz",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "ARS",
        label: <Trans>Argentine Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Argentine Peso`),
        name: "Argentine Peso",
        spaceBetweenAmountAndSymbol: true,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "AUD",
        label: <Trans>Australian Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Australian Dollar`),
        name: "Australian Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "AWG",
        label: <Trans>Aruban Florin</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Aruban Florin`),
        name: "Aruban Florin",
        spaceBetweenAmountAndSymbol: false,
        symbol: "ƒ",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "AZN",
        label: <Trans>Azerbaijan Manat</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Azerbaijan Manat`),
        name: "Azerbaijan Manat",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₼",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "BAM",
        label: <Trans>Convertible Mark</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Convertible Mark`),
        name: "Convertible Mark",
        spaceBetweenAmountAndSymbol: true,
        symbol: "KM",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "BBD",
        label: <Trans>Barbados Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Barbados Dollar`),
        name: "Barbados Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BDT",
        label: <Trans>Taka</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Taka`),
        name: "Taka",
        spaceBetweenAmountAndSymbol: true,
        symbol: "৳",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BGN",
        label: <Trans>Bulgarian Lev</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Bulgarian Lev`),
        name: "Bulgarian Lev",
        spaceBetweenAmountAndSymbol: true,
        symbol: "лв",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "BHD",
        label: <Trans>Bahraini Dinar</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Bahraini Dinar`),
        name: "Bahraini Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: ".د.ب",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BIF",
        label: <Trans>Burundi Franc</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Burundi Franc`),
        name: "Burundi Franc",
        spaceBetweenAmountAndSymbol: false,
        symbol: "FBu",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "BMD",
        label: <Trans>Bermudian Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Bermudian Dollar`),
        name: "Bermudian Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BND",
        label: <Trans>Brunei Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Brunei Dollar`),
        name: "Brunei Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "BOB",
        label: <Trans>Boliviano</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Boliviano`),
        name: "Boliviano",
        spaceBetweenAmountAndSymbol: true,
        symbol: "$b",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "BOV",
        label: <Trans>Mvdol</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Mvdol`),
        name: "Mvdol",
        spaceBetweenAmountAndSymbol: true,
        symbol: "BOV",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BRL",
        label: <Trans>Brazilian Real</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Brazilian Real`),
        name: "Brazilian Real",
        spaceBetweenAmountAndSymbol: true,
        symbol: "R$",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "BSD",
        label: <Trans>Bahamian Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Bahamian Dollar`),
        name: "Bahamian Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BTN",
        label: <Trans>Ngultrum</Trans>,
        decimalDigits: 1,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Ngultrum`),
        name: "Ngultrum",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Nu.",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BWP",
        label: <Trans>Pula</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Pula`),
        name: "Pula",
        spaceBetweenAmountAndSymbol: false,
        symbol: "P",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "BYN",
        label: <Trans>Belarusian Ruble</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Belarusian Ruble`),
        name: "Belarusian Ruble",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Br",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "BZD",
        label: <Trans>Belize Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Belize Dollar`),
        name: "Belize Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "BZ$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CAD",
        label: <Trans>Canadian Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Canadian Dollar`),
        name: "Canadian Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CDF",
        label: <Trans>Congolese Franc</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Congolese Franc`),
        name: "Congolese Franc",
        spaceBetweenAmountAndSymbol: false,
        symbol: "FC",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "CHE",
        label: <Trans>WIR Euro</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`WIR Euro`),
        name: "WIR Euro",
        spaceBetweenAmountAndSymbol: true,
        symbol: "CHE",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CHF",
        label: <Trans>Swiss Franc</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Swiss Franc`),
        name: "Swiss Franc",
        spaceBetweenAmountAndSymbol: true,
        symbol: "CHF",
        symbolOnLeft: true,
        thousandsSeparator: "'",
    },
    {
        code: "CHW",
        label: <Trans>WIR Franc</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`WIR Franc`),
        name: "WIR Franc",
        spaceBetweenAmountAndSymbol: true,
        symbol: "CHW",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CLF",
        label: <Trans>Unidad de Fomento</Trans>,
        decimalDigits: 4,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Unidad de Fomento`),
        name: "Unidad de Fomento",
        spaceBetweenAmountAndSymbol: true,
        symbol: "CLF",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CLP",
        label: <Trans>Chilean Peso</Trans>,
        decimalDigits: 0,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Chilean Peso`),
        name: "Chilean Peso",
        spaceBetweenAmountAndSymbol: true,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "CNY",
        label: <Trans>Yuan Renminbi</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Yuan Renminbi`),
        name: "Yuan Renminbi",
        spaceBetweenAmountAndSymbol: false,
        symbol: "¥",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "COP",
        label: <Trans>Colombian Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Colombian Peso`),
        name: "Colombian Peso",
        spaceBetweenAmountAndSymbol: true,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "COU",
        label: <Trans>Unidad de Valor Real</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Unidad de Valor Real`),
        name: "Unidad de Valor Real",
        spaceBetweenAmountAndSymbol: true,
        symbol: "COU",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CRC",
        label: <Trans>Costa Rican Colon</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Costa Rican Colon`),
        name: "Costa Rican Colon",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₡",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "CUC",
        label: <Trans>Peso Convertible</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Peso Convertible`),
        name: "Peso Convertible",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CUP",
        label: <Trans>Cuban Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Cuban Peso`),
        name: "Cuban Peso",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₱",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CVE",
        label: <Trans>Cabo Verde Escudo</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Cabo Verde Escudo`),
        name: "Cabo Verde Escudo",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "CZK",
        label: <Trans>Czech Koruna</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Czech Koruna`),
        name: "Czech Koruna",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Kč",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "DKK",
        label: <Trans>Danish Krone</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Danish Krone`),
        name: "Danish Krone",
        spaceBetweenAmountAndSymbol: true,
        symbol: "kr",
        symbolOnLeft: false,
        thousandsSeparator: "",
    },
    {
        code: "DOP",
        label: <Trans>Dominican Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Dominican Peso`),
        name: "Dominican Peso",
        spaceBetweenAmountAndSymbol: false,
        symbol: "RD$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "DZD",
        label: <Trans>Algerian Dinar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Algerian Dinar`),
        name: "Algerian Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "دج",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "EGP",
        label: <Trans>Egyptian Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Egyptian Pound`),
        name: "Egyptian Pound",
        spaceBetweenAmountAndSymbol: true,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "ERN",
        label: <Trans>Nakfa</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Nakfa`),
        name: "Nakfa",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Nfk",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "ETB",
        label: <Trans>Ethiopian Birr</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Ethiopian Birr`),
        name: "Ethiopian Birr",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Br",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "FJD",
        label: <Trans>Fiji Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Fiji Dollar`),
        name: "Fiji Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "FKP",
        label: <Trans>Falkland Islands Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Falkland Islands Pound`),
        name: "Falkland Islands Pound",
        spaceBetweenAmountAndSymbol: false,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "GBP",
        label: <Trans>Pound Sterling</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Pound Sterling`),
        name: "Pound Sterling",
        spaceBetweenAmountAndSymbol: false,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "GEL",
        label: <Trans>Lari</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Lari`),
        name: "Lari",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₾",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "GHS",
        label: <Trans>Ghana Cedi</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Ghana Cedi`),
        name: "Ghana Cedi",
        spaceBetweenAmountAndSymbol: false,
        symbol: "GH₵",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "GIP",
        label: <Trans>Gibraltar Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Gibraltar Pound`),
        name: "Gibraltar Pound",
        spaceBetweenAmountAndSymbol: false,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "GMD",
        label: <Trans>Dalasi</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Dalasi`),
        name: "Dalasi",
        spaceBetweenAmountAndSymbol: false,
        symbol: "D",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "GNF",
        label: <Trans>Guinean Franc</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Guinean Franc`),
        name: "Guinean Franc",
        spaceBetweenAmountAndSymbol: false,
        symbol: "FG",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "GTQ",
        label: <Trans>Quetzal</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Quetzal`),
        name: "Quetzal",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Q",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "GYD",
        label: <Trans>Guyana Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Guyana Dollar`),
        name: "Guyana Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "HKD",
        label: <Trans>Hong Kong Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Hong Kong Dollar`),
        name: "Hong Kong Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "HNL",
        label: <Trans>Lempira</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Lempira`),
        name: "Lempira",
        spaceBetweenAmountAndSymbol: true,
        symbol: "L",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "HTG",
        label: <Trans>Gourde</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Gourde`),
        name: "Gourde",
        spaceBetweenAmountAndSymbol: false,
        symbol: "G",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "HUF",
        label: <Trans>Forint</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Forint`),
        name: "Forint",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Ft",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "IDR",
        label: <Trans>Rupiah</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Rupiah`),
        name: "Rupiah",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Rp",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "ILS",
        label: <Trans>New Israeli Sheqel</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`New Israeli Sheqel`),
        name: "New Israeli Sheqel",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₪",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "INR",
        label: <Trans>Indian Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Indian Rupee`),
        name: "Indian Rupee",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₹",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "IQD",
        label: <Trans>Iraqi Dinar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Iraqi Dinar`),
        name: "Iraqi Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "ع.د",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "IRR",
        label: <Trans>Iranian Rial</Trans>,
        decimalDigits: 2,
        decimalSeparator: "/",
        labelAsString: (i18n: I18n) => i18n._(t`Iranian Rial`),
        name: "Iranian Rial",
        spaceBetweenAmountAndSymbol: true,
        symbol: "﷼",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "ISK",
        label: <Trans>Iceland Krona</Trans>,
        decimalDigits: 0,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Iceland Krona`),
        name: "Iceland Krona",
        spaceBetweenAmountAndSymbol: true,
        symbol: "kr",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "JMD",
        label: <Trans>Jamaican Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Jamaican Dollar`),
        name: "Jamaican Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "J$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "JOD",
        label: <Trans>Jordanian Dinar</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Jordanian Dinar`),
        name: "Jordanian Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "JD",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "JPY",
        label: <Trans>Yen</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Yen`),
        name: "Yen",
        spaceBetweenAmountAndSymbol: false,
        symbol: "¥",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KES",
        label: <Trans>Kenyan Shilling</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Kenyan Shilling`),
        name: "Kenyan Shilling",
        spaceBetweenAmountAndSymbol: false,
        symbol: "KSh",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KGS",
        label: <Trans>Som</Trans>,
        decimalDigits: 2,
        decimalSeparator: "-",
        labelAsString: (i18n: I18n) => i18n._(t`Som`),
        name: "Som",
        spaceBetweenAmountAndSymbol: true,
        symbol: "лв",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "KHR",
        label: <Trans>Riel</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Riel`),
        name: "Riel",
        spaceBetweenAmountAndSymbol: false,
        symbol: "៛",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "KMF",
        label: <Trans>Comorian Franc </Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Comorian Franc`),
        name: "Comorian Franc",
        spaceBetweenAmountAndSymbol: false,
        symbol: "CF",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "KPW",
        label: <Trans>North Korean Won</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`North Korean Won`),
        name: "North Korean Won",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₩",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KRW",
        label: <Trans>Won</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Won`),
        name: "Won",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₩",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KWD",
        label: <Trans>Kuwaiti Dinar</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Kuwaiti Dinar`),
        name: "Kuwaiti Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "KD",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KYD",
        label: <Trans>Cayman Islands Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Cayman Islands Dollar`),
        name: "Cayman Islands Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "KZT",
        label: <Trans>Tenge</Trans>,
        decimalDigits: 2,
        decimalSeparator: "-",
        labelAsString: (i18n: I18n) => i18n._(t`Tenge`),
        name: "Tenge",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₸",
        symbolOnLeft: true,
        thousandsSeparator: " ",
    },
    {
        code: "LAK",
        label: <Trans>Lao Kip</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Lao Kip`),
        name: "Lao Kip",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₭",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "LBP",
        label: <Trans>Lebanese Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Lebanese Pound`),
        name: "Lebanese Pound",
        spaceBetweenAmountAndSymbol: true,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "LKR",
        label: <Trans>Sri Lanka Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Sri Lanka Rupee`),
        name: "Sri Lanka Rupee",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₨",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "LRD",
        label: <Trans>Liberian Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Liberian Dollar`),
        name: "Liberian Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "LSL",
        label: <Trans>Loti</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Loti`),
        name: "Loti",
        spaceBetweenAmountAndSymbol: false,
        symbol: "M",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "LYD",
        label: <Trans>Libyan Dinar</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Libyan Dinar`),
        name: "Libyan Dinar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "LD",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MAD",
        label: <Trans>Moroccan Dirham</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Moroccan Dirham`),
        name: "Moroccan Dirham",
        spaceBetweenAmountAndSymbol: true,
        symbol: "MAD",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MDL",
        label: <Trans>Moldovan Leu</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Moldovan Leu`),
        name: "Moldovan Leu",
        spaceBetweenAmountAndSymbol: true,
        symbol: "lei",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "MGA",
        label: <Trans>Malagasy Ariary</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Malagasy Ariary`),
        name: "Malagasy Ariary",
        spaceBetweenAmountAndSymbol: false,
        symbol: "Ar",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MKD",
        label: <Trans>Denar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Denar`),
        name: "Denar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "ден",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "MMK",
        label: <Trans>Kyat</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Kyat`),
        name: "Kyat",
        spaceBetweenAmountAndSymbol: false,
        symbol: "K",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MNT",
        label: <Trans>Tugrik</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Tugrik`),
        name: "Tugrik",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₮",
        symbolOnLeft: true,
        thousandsSeparator: " ",
    },
    {
        code: "MOP",
        label: <Trans>Pataca</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Pataca`),
        name: "Pataca",
        spaceBetweenAmountAndSymbol: false,
        symbol: "MOP$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MRU",
        label: <Trans>Ouguiya</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Ouguiya`),
        name: "Ouguiya",
        spaceBetweenAmountAndSymbol: true,
        symbol: "UM",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MUR",
        label: <Trans>Mauritius Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Mauritius Rupee`),
        name: "Mauritius Rupee",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₨",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MVR",
        label: <Trans>Rufiyaa</Trans>,
        decimalDigits: 1,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Rufiyaa`),
        name: "Rufiyaa",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Rf",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "MWK",
        label: <Trans>Malawi Kwacha</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Malawi Kwacha`),
        name: "Malawi Kwacha",
        spaceBetweenAmountAndSymbol: false,
        symbol: "MK",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MXN",
        label: <Trans>Mexican Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Mexican Peso`),
        name: "Mexican Peso",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MXV",
        label: <Trans>Mexican Unidad de Inversion (UDI)</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Mexican Unidad de Inversion (UDI)`),
        name: "Mexican Unidad de Inversion (UDI)",
        spaceBetweenAmountAndSymbol: true,
        symbol: "MXV",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MYR",
        label: <Trans>Malaysian Ringgit</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Malaysian Ringgit`),
        name: "Malaysian Ringgit",
        spaceBetweenAmountAndSymbol: false,
        symbol: "RM",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "MZN",
        label: <Trans>Mozambique Metical</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Mozambique Metical`),
        name: "Mozambique Metical",
        spaceBetweenAmountAndSymbol: false,
        symbol: "MT",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "NAD",
        label: <Trans>Namibia Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Namibia Dollar`),
        name: "Namibia Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "NGN",
        label: <Trans>Naira</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Naira`),
        name: "Naira",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₦",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "NIO",
        label: <Trans>Cordoba Oro</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Cordoba Oro`),
        name: "Cordoba Oro",
        spaceBetweenAmountAndSymbol: true,
        symbol: "C$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "NOK",
        label: <Trans>Norwegian Krone</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Norwegian Krone`),
        name: "Norwegian Krone",
        spaceBetweenAmountAndSymbol: true,
        symbol: "kr",
        symbolOnLeft: true,
        thousandsSeparator: " ",
    },
    {
        code: "NPR",
        label: <Trans>Nepalese Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Nepalese Rupee`),
        name: "Nepalese Rupee",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₨",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "NZD",
        label: <Trans>New Zealand Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`New Zealand Dollar`),
        name: "New Zealand Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "OMR",
        label: <Trans>Rial Omani</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Rial Omani`),
        name: "Rial Omani",
        spaceBetweenAmountAndSymbol: true,
        symbol: "﷼",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PAB",
        label: <Trans>Balboa</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Balboa`),
        name: "Balboa",
        spaceBetweenAmountAndSymbol: true,
        symbol: "B/.",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PEN",
        label: <Trans>Sol</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Sol`),
        name: "Sol",
        spaceBetweenAmountAndSymbol: true,
        symbol: "S/.",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PGK",
        label: <Trans>Kina</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Kina`),
        name: "Kina",
        spaceBetweenAmountAndSymbol: false,
        symbol: "K",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PHP",
        label: <Trans>Philippine Peso</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Philippine Peso`),
        name: "Philippine Peso",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₱",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PLN",
        label: <Trans>Zloty</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Zloty`),
        name: "Zloty",
        spaceBetweenAmountAndSymbol: true,
        symbol: "zł",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "PKR",
        label: <Trans>Pakistan Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Pakistan Rupee`),
        name: "Pakistan Rupee",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₨",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "PYG",
        label: <Trans>Guarani</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Guarani`),
        name: "Guarani",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Gs",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "QAR",
        label: <Trans>Qatari Rial</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Qatari Rial`),
        name: "Qatari Rial",
        spaceBetweenAmountAndSymbol: true,
        symbol: "﷼",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "RON",
        label: <Trans>Romanian Leu</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Romanian Leu`),
        name: "Romanian Leu",
        spaceBetweenAmountAndSymbol: true,
        symbol: "lei",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "RSD",
        label: <Trans>Serbian Dinar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Serbian Dinar`),
        name: "Serbian Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Дин.",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "RUB",
        label: <Trans>Russian Ruble</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Russian Ruble`),
        name: "Russian Ruble",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₽",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "RWF",
        label: <Trans>Rwanda Franc</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Rwanda Franc`),
        name: "Rwanda Franc",
        spaceBetweenAmountAndSymbol: true,
        symbol: "R₣",
        symbolOnLeft: true,
        thousandsSeparator: " ",
    },
    {
        code: "SAR",
        label: <Trans>Saudi Riyal</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Saudi Riyal`),
        name: "Saudi Riyal",
        spaceBetweenAmountAndSymbol: true,
        symbol: "﷼",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SBD",
        label: <Trans>Solomon Islands Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Solomon Islands Dollar`),
        name: "Solomon Islands Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SCR",
        label: <Trans>Seychelles Rupee</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Seychelles Rupee`),
        name: "Seychelles Rupee",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₨",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SDG",
        label: <Trans>Sudanese Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Sudanese Pound`),
        name: "Sudanese Pound",
        spaceBetweenAmountAndSymbol: false,
        symbol: "ج.س.",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SEK",
        label: <Trans>Swedish Krona</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Swedish Krona`),
        name: "Swedish Krona",
        spaceBetweenAmountAndSymbol: true,
        symbol: "kr",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "SGD",
        label: <Trans>Singapore Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Singapore Dollar`),
        name: "Singapore Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "S$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SHP",
        label: <Trans>Saint Helena Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Saint Helena Pound`),
        name: "Saint Helena Pound",
        spaceBetweenAmountAndSymbol: false,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SLE",
        label: <Trans>Leone</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Leone`),
        name: "Leone",
        spaceBetweenAmountAndSymbol: true,
        symbol: "SLE",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SOS",
        label: <Trans>Somali Shilling</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Somali Shilling`),
        name: "Somali Shilling",
        spaceBetweenAmountAndSymbol: false,
        symbol: "S",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SRD",
        label: <Trans>Surinam Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Surinam Dollar`),
        name: "Surinam Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SSP",
        label: <Trans>South Sudanese Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`South Sudanese Pound`),
        name: "South Sudanese Pound",
        spaceBetweenAmountAndSymbol: true,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "STN",
        label: <Trans>Dobra</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Dobra`),
        name: "Dobra",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Db",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SVC",
        label: <Trans>El Salvador Colon</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`El Salvador Colon`),
        name: "El Salvador Colon",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SYP",
        label: <Trans>Syrian Pound</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Syrian Pound`),
        name: "Syrian Pound",
        spaceBetweenAmountAndSymbol: true,
        symbol: "£",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "SZL",
        label: <Trans>Lilangeni</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Lilangeni`),
        name: "Lilangeni",
        spaceBetweenAmountAndSymbol: false,
        symbol: "E",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "THB",
        label: <Trans>Baht</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Baht`),
        name: "Baht",
        spaceBetweenAmountAndSymbol: false,
        symbol: "฿",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "TJS",
        label: <Trans>Somoni</Trans>,
        decimalDigits: 2,
        decimalSeparator: ";",
        labelAsString: (i18n: I18n) => i18n._(t`Somoni`),
        name: "Somoni",
        spaceBetweenAmountAndSymbol: true,
        symbol: "SM",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "TMT",
        label: <Trans>Turkmenistan New Manat</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Turkmenistan New Manat`),
        name: "Turkmenistan New Manat",
        spaceBetweenAmountAndSymbol: false,
        symbol: "T",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "TOP",
        label: <Trans>Pa’anga</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Pa’anga`),
        name: "Pa’anga",
        spaceBetweenAmountAndSymbol: false,
        symbol: "T$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "TRY",
        label: <Trans>Turkish Lira</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Turkish Lira`),
        name: "Turkish Lira",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₺",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "TND",
        label: <Trans>Tunisian Dinar</Trans>,
        decimalDigits: 3,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Tunisian Dinar`),
        name: "Tunisian Dinar",
        spaceBetweenAmountAndSymbol: true,
        symbol: "د.ت",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "TTD",
        label: <Trans>Trinidad and Tobago Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Trinidad and Tobago Dollar`),
        name: "Trinidad and Tobago Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "TT$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "TWD",
        label: <Trans>New Taiwan Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`New Taiwan Dollar`),
        name: "New Taiwan Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "NT$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "TZS",
        label: <Trans>Tanzanian Shilling</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Tanzanian Shilling`),
        name: "Tanzanian Shilling",
        spaceBetweenAmountAndSymbol: false,
        symbol: "TSh",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "UAH",
        label: <Trans>Hryvnia</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Hryvnia`),
        name: "Hryvnia",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₴",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "UGX",
        label: <Trans>Uganda Shilling</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Uganda Shilling`),
        name: "Uganda Shilling",
        spaceBetweenAmountAndSymbol: false,
        symbol: "USh",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "USN",
        label: <Trans>US Dollar (Next day)</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`US Dollar (Next day)`),
        name: "US Dollar (Next day)",
        spaceBetweenAmountAndSymbol: true,
        symbol: "USN",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "UYI",
        label: <Trans>Uruguay Peso en Unidades Indexadas (UI)</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Uruguay Peso en Unidades Indexadas (UI)`),
        name: "Uruguay Peso en Unidades Indexadas (UI)",
        spaceBetweenAmountAndSymbol: true,
        symbol: "UYI",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "UYU",
        label: <Trans>Peso Uruguayo</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Peso Uruguayo`),
        name: "Peso Uruguayo",
        spaceBetweenAmountAndSymbol: true,
        symbol: "$U",
        symbolOnLeft: true,
        thousandsSeparator: ".",
    },
    {
        code: "UZS",
        label: <Trans>Uzbekistan Sum</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Uzbekistan Sum`),
        name: "Uzbekistan Sum",
        spaceBetweenAmountAndSymbol: true,
        symbol: "лв",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "VED",
        label: <Trans>Bolívar Soberano</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Bolívar Soberano`),
        name: "Bolívar Soberano",
        spaceBetweenAmountAndSymbol: true,
        symbol: "VED",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "VND",
        label: <Trans>Dong</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Dong`),
        name: "Dong",
        spaceBetweenAmountAndSymbol: true,
        symbol: "₫",
        symbolOnLeft: false,
        thousandsSeparator: ".",
    },
    {
        code: "VUV",
        label: <Trans>Vatu</Trans>,
        decimalDigits: 0,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Vatu`),
        name: "Vatu",
        spaceBetweenAmountAndSymbol: false,
        symbol: "VT",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "WST",
        label: <Trans>Tala</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Tala`),
        name: "Tala",
        spaceBetweenAmountAndSymbol: false,
        symbol: "WS$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "XAF",
        label: <Trans>CFA Franc BEAC</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`CFA Franc BEAC`),
        name: "CFA Franc BEAC",
        spaceBetweenAmountAndSymbol: false,
        symbol: "CFA",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "XCD",
        label: <Trans>East Caribbean Dollar</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`East Caribbean Dollar`),
        name: "East Caribbean Dollar",
        spaceBetweenAmountAndSymbol: false,
        symbol: "$",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "XDR",
        label: <Trans>SDR (Special Drawing Right)</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`SDR (Special Drawing Right)`),
        name: "SDR (Special Drawing Right)",
        spaceBetweenAmountAndSymbol: true,
        symbol: "XDR",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "XOF",
        label: <Trans>CFA Franc BCEAO</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`CFA Franc BCEAO`),
        name: "CFA Franc BCEAO",
        spaceBetweenAmountAndSymbol: false,
        symbol: "CFA",
        symbolOnLeft: false,
        thousandsSeparator: " ",
    },
    {
        code: "XPF",
        label: <Trans>CFP Franc</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`CFP Franc`),
        name: "CFP Franc",
        spaceBetweenAmountAndSymbol: false,
        symbol: "₣",
        symbolOnLeft: false,
        thousandsSeparator: ",",
    },
    {
        code: "XSU",
        label: <Trans>Sucre</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Sucre`),
        name: "Sucre",
        spaceBetweenAmountAndSymbol: true,
        symbol: "Sucre",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "XUA",
        label: <Trans>ADB Unit of Account</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`ADB Unit of Account`),
        name: "ADB Unit of Account",
        spaceBetweenAmountAndSymbol: true,
        symbol: "XUA",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "YER",
        label: <Trans>Yemeni Rial</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Yemeni Rial`),
        name: "Yemeni Rial",
        spaceBetweenAmountAndSymbol: true,
        symbol: "﷼",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
    {
        code: "ZAR",
        label: <Trans>Rand</Trans>,
        decimalDigits: 2,
        decimalSeparator: ",",
        labelAsString: (i18n: I18n) => i18n._(t`Rand`),
        name: "Rand",
        spaceBetweenAmountAndSymbol: false,
        symbol: "R",
        symbolOnLeft: true,
        thousandsSeparator: " ",
    },
    {
        code: "ZMW",
        label: <Trans>Zambian Kwacha</Trans>,
        decimalDigits: 2,
        decimalSeparator: ".",
        labelAsString: (i18n: I18n) => i18n._(t`Zambian Kwacha`),
        name: "Zambian Kwacha",
        spaceBetweenAmountAndSymbol: false,
        symbol: "ZK",
        symbolOnLeft: true,
        thousandsSeparator: ",",
    },
];

export default availableCurrencies;
