//Import libraries
import React from "react";

// Import components.
import { Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";

// Import Icons.
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

// Import Utils.
import NumberFormatter from "utils/formatters/Number";

interface PROPS {
    style?: React.CSSProperties;
    percentage: number;
    decimalScale?: number;
    tooltipTitle?: React.ReactNode;
    showDash?: boolean;
}

class TrendArrow extends React.Component<PROPS> {
    renderLabelContent = () => {
        const { percentage, decimalScale = 0, style, showDash } = this.props;

        const fixedPercentage = Number(Math.abs(percentage).toFixed(decimalScale));

        if (fixedPercentage === 0 || Number.isNaN(fixedPercentage) || !Number.isFinite(fixedPercentage)) {
            return <Typography style={{ fontSize: "inherit", ...style }}>{showDash ? "-" : ""}</Typography>;
        }

        return (
            <span key={"trend-arrow-percentage"} style={{ display: "flex", alignItems: "center", color: percentage >= 0 ? "var(--label-positive-color)" : "var(--label-negative-color)", ...style }}>
                {percentage >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}

                <Typography component={"span"} style={{ flex: "0 0 auto", display: "flex", alignItems: "center", fontSize: "inherit" }}>
                    {decimalScale > 0 && !Number.isInteger(fixedPercentage) ? NumberFormatter.formatFloat(fixedPercentage, decimalScale) : NumberFormatter.formatInteger(fixedPercentage)} %
                </Typography>
            </span>
        );
    };

    render() {
        const { tooltipTitle } = this.props;

        return (
            <span key={"trend-arrow"}>
                {tooltipTitle ? (
                    <Tooltip alwaysShow title={tooltipTitle}>
                        {this.renderLabelContent()}
                    </Tooltip>
                ) : (
                    this.renderLabelContent()
                )}
            </span>
        );
    }
}

export default TrendArrow;
