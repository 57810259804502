// Import libraries
import { I18n } from "@lingui/core";
import { call, put, all, takeLatest, select } from "redux-saga/effects";
import QueryString from "query-string";

// Import types.
import AuthType from "types/enums/AuthType";
import PortalState from "types/store";
import ApplicationInformation from "types/common/ApplicationInformation";
import EnvironmentInformation from "types/common/EnvironmentInformation";
import ThemeSettings from "types/common/ThemeSettings";
import Session, { resetSession } from "types/common/Session";
import ServerStatus from "types/common/ServerStatus";
import AccessMode, { parseAccessMode } from "types/enums/AccessMode";

// Import redux actions.
import { SET_ENVIRONMENT_INFORMATION } from "store/actions/env";
import { SET_APPLICATION_INFORMATION } from "store/actions/app";
import { SET_SYSTEM_ROLES } from "store/actions/systemRoles";
import { SET_SESSION } from "store/actions/session";
import { SET_CURRENT_USER } from "store/actions/currentUser";
import { SET_CURRENT_COMPANY_ALIAS } from "store/actions/currentCompanyAlias";
import { SET_AVAILABLE_COMPANIES } from "store/actions/availableCompanies";
import { SET_AVAILABLE_APPS } from "store/actions/availableApps";
import { SET_SCREEN_SETTINGS } from "store/actions/screenSettings";
import { SET_AVAILABLE_PRIVILEGES } from "store/actions/availablePrivileges";
import { SET_THEME_SETTINGS } from "store/actions/theme";

// Import redux sagas.
import { restoreSession, saveSession } from "./sessionSagas";
import { loadTheme } from "./themeSagas";
import { populateSystemRoles } from "./systemRoleSagas";
import { populateCurrentUser } from "./userSagas";
import { populateCurrentCompanyAlias, populateAvailableCompanies } from "./companySagas";
import { populateAvailableApps } from "./appSagas";
import { completeLogin, logout } from "./authenticationSagas";

// Import utilities.
import Http, { HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";
import CookieConsentUtils, { CookieConsentLevel } from "utils/CookieConsent";
import LocalStorageUtils from "utils/LocalStorage";

// Import services.
import BrainCloudPropertiesService from "services/BrainCloudProperties";
import StatusService from "services/Status";

interface InitializeApplication {
    type: "initialization.initializeApplication";
    payload: {
        i18n: I18n;
        name: string | null;
        prefix: string | null;
        version: string | null;
        build: string | null;
    };
}

interface PopulateBasicState {
    type: "initialization.populateBasicState";
    payload: {
        i18n: I18n;
        mode: "login" | "full";
    };
}

interface ClearBasicState {
    type: "initialization.clearBasicState";
    payload: {
        mode: "login" | "full";
    };
}

interface CheckServerStatus {
    type: "initialization.checkServerStatus";
}

interface RefreshPortalDisabledState {
    type: "initialization.refreshPortalDisabledState";
}

const extractAndSanitizeLocationParameters = () => {
    // Extract any query string parameters that may have been supplied.
    let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
    if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));

    const queryParams = queryString ? QueryString.parse(queryString) : {};

    // Extract any hash/doc-frag that may have been supplied.
    let hashString = window.location.href.indexOf("#") >= 0 ? window.location.href.substring(window.location.href.indexOf("#") + 1) : null;
    if (hashString) hashString = hashString.trim();
    if (hashString && hashString.length === 0) hashString = null;

    /*** BACKWARDS-COMPATABILITY (for supporting certain links intended for the Legacy Portal): ***/
    //
    // Processing account activation link from email.
    //     - The current link is actually more or less ok, we have Apache rewrite the requested URL.
    //     - See the "httpd.conf" file for relavent RewriteRule's.
    //
    // Processing account invitation link from email.
    //     - The current link is actually more or less ok, we have Apache rewrite the requested URL.
    //     - See the "httpd.conf" file for relavent RewriteRule's.
    //
    // Processing reset password link from email (based on docfrag/hash, so we need some "extra" processing here).
    //     - The current link is pretty bad (hash/docfrag based), we have Apache rewrite the requested URL.
    //     - See the "httpd.conf" file for relavent RewriteRule's.
    //     - We also process the hash/docfrag here and replace the state of the history (Apache doesn't have access to the hash/docfrag).
    //

    // Support the legacy reset password link.
    const hashMatches = hashString ? hashString.match("\\/changePassword\\/email\\/([^\\/]*)\\/token\\/([^\\/]*)") : null;
    if (hashMatches && hashMatches.length > 2 && hashMatches[1].trim().length > 0 && hashMatches[2].trim().length > 0) {
        // If the docfrag/hash matches what the :legacy Portal accepted as a "reset password" with email/token, then force a redirect with the proper query string paramaeters.
        queryParams["email"] = decodeURIComponent(hashMatches[1]);
        queryParams["recoveryToken"] = decodeURIComponent(hashMatches[2]);

        window.history.replaceState(null, "", "?email=" + encodeURIComponent(decodeURIComponent(hashMatches[1])) + "&recoveryToken=" + encodeURIComponent(decodeURIComponent(hashMatches[2])));

        hashString = null;
    } else {
        if (hashString && hashString.startsWith("/implicit/callback")) {
            hashString = null;
        }
    }
    /*** DONE ***/

    return {
        queryParams,
        hashString,
    };
};

// Initializes the portal application (i.e. loads minimum properties and data from the server that is not technically dependant on an authenticated session).
export function* initializeApplication(action: InitializeApplication) {
    // Set up the initial application information.

    let applicationInformation: ApplicationInformation = {
        name: action.payload.name || "NOT_SET",
        prefix: action.payload.prefix || "NOT_SET",
        version: action.payload.version || "NOT_SET",
        build: action.payload.build || "NOT_SET",
        disabled: false,
        status: null,
        platformVersion: null,
        loadingInitialState: false,
        loadingLoginState: false,
        loadingBasicState: false,
        loginConfiguration: {
            emailPassword: false,
            emailPasswordAdminAllowed: false,
            availableExternalAuthenticationProviders: [],
        },
        twoFactorConfiguration: {
            enabled: false,
            authyAvailable: false,
            verifyAvailable: false,
        },
        intercomConfiguration: {
            enabled: false,
            appId: null,
        },
        billingConfiguration: {
            enabled: false,
        },
        serverStatusConfiguration: {
            enabled: false,
        },
        serverStatus: null,
        accessMode: AccessMode.DISABLED,

        systemMessage: {
            message: {
                en: null,
                fr: null,
            },
            urgencyLevel: 0,
            isDismissed: false,
        },

        maxFileUploadSizeInBytes: -1,
        hideLogoutMenuItem: false,
        memberCanCreateTeam: true,
        selfRegistrationEnabled: true,
        hideSwitchToLegacyView: false,
    };

    // Define some getters for retrieving redux state.
    const getEnvironmentInformation = (state: PortalState): EnvironmentInformation => CloneUtils.clone(state.environmentInformation);
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);

    try {
        console.log("Attempting To Initialize Application...");

        // Extract any query string parameters that may have been supplied.
        const locationParameters = extractAndSanitizeLocationParameters();
        const { queryParams, hashString } = locationParameters;

        // Update the current application information.
        applicationInformation.status = "initializing";
        applicationInformation.loadingInitialState = true;
        yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

        // Setup the page title.
        const productName: string = yield BrainCloudPropertiesService.getSystemProperty("productName");
        if (!StringUtils.isNullOrEmpty(productName)) {
            document.title = productName.trim();
        } else {
            document.title = process.env.REACT_APP_NAME || "brainCloud Portal-X";
        }

        // Setup Google Analytics.
        const googleAnalyticsMeasurementId: string = yield BrainCloudPropertiesService.getSystemProperty("googleAnalyticsMeasurementId");
        if (!StringUtils.isNullOrEmpty(googleAnalyticsMeasurementId)) {
            console.log("Google Analytics are ENABLED");

            if ((window as any).gtag) {
                (window as any).gtag("js", new Date());
                (window as any).gtag("config", googleAnalyticsMeasurementId, { page_title: document.title });
            } else {
                // console.log("Google Analytics are USER DISABLED");

                (window as any).gtag = () => {
                    return;
                };
            }
        } else {
            // console.log("Google Analytics are DISABLED");

            (window as any).gtag = () => {
                return;
            };
        }

        // Clear out any data that should not exist based on cookie consent preferneces.
        CookieConsentUtils.clearUnauthorizedData();

        // Attempt to restore the session state.
        // This is ONLY supported when the cookie consent permits the FUNCTIONALITY consent level (we need to remember the user's session details in order to do this).
        yield call(restoreSession);

        // Get the current session.
        const session: Session = yield select(getSession);

        // Setup the default theme settings.
        const portalxThemeConfig: ThemeSettings = yield BrainCloudPropertiesService.getSystemProperty("portalxThemeConfig");
        if (portalxThemeConfig) {
            // Save the current theme settings.
            yield put(SET_THEME_SETTINGS(CloneUtils.clone(portalxThemeConfig)));

            // Apply the default theme mode.
            session.themeMode = portalxThemeConfig.defaultMode === "dark" ? "dark" : "light";
            yield put(SET_SESSION(CloneUtils.clone(session)));
            yield call(saveSession);

            LocalStorageUtils.setItem(LocalStorageUtils.DEFAULT_THEME_MODE, portalxThemeConfig.defaultMode === "dark" ? "dark" : "light", CookieConsentLevel.FUNCTIONALITY);
        }

        // Populate the initial environment information.
        const environmentResponse: HttpResponse = yield Http.GET(window.location.origin + "/env.json", undefined, Http.JSON_HEADERS);
        if (Http.isStatusOk(environmentResponse) && environmentResponse.data) {
            const environmentInformation: EnvironmentInformation = environmentResponse.data;

            // Clear any environment information fields that contain a placeholder (i.e. "%...%").
            // These are the result of no substitution being performed as a result of missing environment variables on the host server.
            Object.keys(environmentInformation).forEach((key) => {
                const value = "" + (environmentInformation as any)[key];

                const placeholderRedgex = /(%.*%)/g;

                const placeholderMatch = value.match(placeholderRedgex);

                if (placeholderMatch && placeholderMatch.length > 0) {
                    (environmentInformation as any)[key] = null;
                }
            });

            // Retrieve some system properties from the server.
            // Specifically we fetch the support email address, the legacy portal domain name and the portal-x doamin name.
            // These will be used to populate the associated fields in the EnvironmentInformaiton data.
            const [supportEmailAddressResponse, portalDomainNameResponse, portalxDomainNameResponse, portalxCarouselUrlResponse]: PromiseSettledResult<any>[] = yield Promise.allSettled([
                BrainCloudPropertiesService.getSystemProperty("supportEmailAddress"),
                BrainCloudPropertiesService.getSystemProperty("portalDomainName"),
                BrainCloudPropertiesService.getSystemProperty("portalxDomainName"),
                BrainCloudPropertiesService.getSystemProperty("portalxCarouselUrl"),
            ]);

            // Process the support email address property.
            if (supportEmailAddressResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(supportEmailAddressResponse.value)) {
                environmentInformation.supportEmail = supportEmailAddressResponse.value;
            } else {
                environmentInformation.supportEmail = null;
            }

            // Process the portal domain name property.
            if (portalDomainNameResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(portalDomainNameResponse.value)) {
                environmentInformation.portalDomainName = portalDomainNameResponse.value;
            } else {
                environmentInformation.portalDomainName = null;
            }

            // Process the portal-x domain name property.
            if (portalxDomainNameResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(portalxDomainNameResponse.value)) {
                environmentInformation.portalxDomainName = portalxDomainNameResponse.value;
            } else {
                environmentInformation.portalxDomainName = null;
            }

            // Process the portal-x carousel url property.
            if (portalxCarouselUrlResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(portalxCarouselUrlResponse.value)) {
                environmentInformation.carouselUrl = portalxCarouselUrlResponse.value;
            } else {
                environmentInformation.carouselUrl = null;
            }

            // Save the current environment information.
            yield put(SET_ENVIRONMENT_INFORMATION(CloneUtils.clone(environmentInformation)));
        }

        // When running locally via the dev server (i.e. the process.env.NODE_ENV is something other than "production"), this information is not actually present.
        // So we assign some generic defaults.
        if (process.env.NODE_ENV !== "production") {
            let environmentInformation: EnvironmentInformation = yield select(getEnvironmentInformation);

            environmentInformation.supportEmail = "support@getbraincloud.com";

            environmentInformation.portalDomainName = "portal.internal.braincloudservers.com";
            environmentInformation.portalxDomainName = "portalx.internal.braincloudservers.com";

            // Save the current environment information.
            yield put(SET_ENVIRONMENT_INFORMATION(CloneUtils.clone(environmentInformation)));
        }

        // Retrieve and process some general system properties.
        const [portalDisabledResponse, portalxAccessModeResponse, hideLogoutMenuItemResponse, memberCanCreateTeamResponse, selfRegistrationEnabledResponse]: PromiseSettledResult<any>[] = yield Promise.allSettled([
            BrainCloudPropertiesService.getSystemProperty("portalDisabled"),
            BrainCloudPropertiesService.getSystemProperty("portalxAccessMode"),
            BrainCloudPropertiesService.getSystemProperty("hideLogoutMenuItem"),
            BrainCloudPropertiesService.getSystemProperty("memberCanCreateTeam"),
            BrainCloudPropertiesService.getSystemProperty("selfRegistrationEnabled"),
        ]);
        if (portalDisabledResponse.status === "fulfilled") applicationInformation.disabled = StringUtils.isTruthy(portalDisabledResponse.value);
        if (portalxAccessModeResponse.status === "fulfilled") applicationInformation.accessMode = parseAccessMode(portalxAccessModeResponse.value);
        if (hideLogoutMenuItemResponse.status === "fulfilled") applicationInformation.hideLogoutMenuItem = StringUtils.isTruthy(hideLogoutMenuItemResponse.value);
        if (memberCanCreateTeamResponse.status === "fulfilled") applicationInformation.memberCanCreateTeam = StringUtils.isTruthy(memberCanCreateTeamResponse.value);
        if (selfRegistrationEnabledResponse.status === "fulfilled") applicationInformation.selfRegistrationEnabled = StringUtils.isTruthy(selfRegistrationEnabledResponse.value);

        // Load and activate the default theme.
        // Taking into account to optional "custom" query string parameter that may be present.
        // This indicates a custom theme to load.
        yield call(loadTheme, { type: "theme.loadTheme", payload: { companyId: null, custom: queryParams.custom && typeof queryParams.custom === "string" ? (queryParams.custom as string) : null, activate: true } });

        // Retrieve the portal login configuration and some system properties from the server.
        // Sepcifically we fetch the the intercom and status page properties.
        const [portalLoginConfigResponse, intercomEnabledResponse, intercomAppIdResponse, statusPageEnabledResponse, statusPageUrlResponse, statusPageIdResponse]: PromiseSettledResult<any>[] = yield Promise.allSettled([
            Http.GET("portalloginconfig", undefined, Http.JSON_HEADERS),
            BrainCloudPropertiesService.getSystemProperty("intercomEnabled"),
            BrainCloudPropertiesService.getSystemProperty("intercomAppId"),
            BrainCloudPropertiesService.getSystemProperty("statusPageEnabled"),
            BrainCloudPropertiesService.getSystemProperty("statusPageUrl"),
            BrainCloudPropertiesService.getSystemProperty("statusPageId"),
        ]);

        // Ensure that everything that is considered REQUIRED was retrieved successfully.
        // All of these properties should be publicly available, so if any of them fail then there is an issue.
        if (portalLoginConfigResponse.status !== "fulfilled" || !Http.isStatusOk(portalLoginConfigResponse.value as HttpResponse) || Http.isRedirect(portalLoginConfigResponse.value as HttpResponse)) {
            throw new Error("Unable to fetch necessary system properties");
        }

        // Process the portal login configuration.
        if (portalLoginConfigResponse.value?.data) {
            applicationInformation.loginConfiguration = {
                emailPassword: portalLoginConfigResponse.value.data.emailPassword || false,
                emailPasswordAdminAllowed: portalLoginConfigResponse.value.data.adminEmailLoginAllowed || false,
                availableExternalAuthenticationProviders: [],
            };

            // Add a default/fallback external provider (in case no SSO links were returned from the server).
            applicationInformation.loginConfiguration.availableExternalAuthenticationProviders.push({
                type: "unknown",
                params: {
                    label: "",
                    ssoUrl: window.location.origin + "/login/sso",
                    redirectUrl: window.location.origin + "/#/implicit/callback",
                },
            });

            // Process the SSO links that were returned from the servewr.
            if (Array.isArray(portalLoginConfigResponse.value.data.ssoLinks) && portalLoginConfigResponse.value.data.ssoLinks.length > 0) {
                portalLoginConfigResponse.value.data.ssoLinks.forEach((ssoLink: any) => {
                    if (!ssoLink) return;

                    console.log("Processing SSO Link", ssoLink);

                    const parts = ssoLink.url ? (ssoLink.url as string).split("/") : [];

                    // Convert SSOLink into a proper external authentication configuration instance (if possible).
                    applicationInformation.loginConfiguration.availableExternalAuthenticationProviders.push({
                        type: parts.length > 0 ? parts[parts.length - 1] : "other",
                        params: {
                            label: ssoLink.label ? ssoLink.label : "SSO Login",
                            ssoUrl: ssoLink.url ? ssoLink.url : "",
                            redirectUrl: window.location.origin + "/#/implicit/callback",
                        },
                    });
                });
            }
        }

        // Process the intercom properties.
        if (intercomEnabledResponse.status === "fulfilled" && StringUtils.isTruthy(intercomEnabledResponse.value)) {
            applicationInformation.intercomConfiguration.enabled = true;

            if (intercomAppIdResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(intercomAppIdResponse.value)) {
                applicationInformation.intercomConfiguration.appId = intercomAppIdResponse.value;
            }
        }

        // Process the status page properties.
        if (statusPageEnabledResponse.status === "fulfilled" && StringUtils.isTruthy(statusPageEnabledResponse.value)) {
            applicationInformation.serverStatusConfiguration.enabled = true;

            if (statusPageUrlResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(statusPageUrlResponse.value)) {
                applicationInformation.serverStatusConfiguration.statusUrl = statusPageUrlResponse.value;
            }

            if (statusPageIdResponse.status === "fulfilled" && !StringUtils.isNullOrEmpty(statusPageIdResponse.value)) {
                applicationInformation.serverStatusConfiguration.statusId = statusPageIdResponse.value;
            }
        }

        // Update the current application information.
        yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

        // Trigger the initial server status check.
        yield call(checkServerStatus);

        // Get service version.
        const serverInformationResponse: HttpResponse = yield Http.GET("/deephealthcheck", undefined, Http.JSON_HEADERS);
        if (Http.isStatusOk(serverInformationResponse) && serverInformationResponse.data) {
            applicationInformation.platformVersion = serverInformationResponse.data.platformVersion;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // Get the current application information (the initial checkServerStatus above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        // Indicate that the application is now READY.
        // Update the current application information.
        applicationInformation.status = "ready";
        yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

        if (
            !Array.isArray(queryParams.email) &&
            !StringUtils.isNullOrEmpty(queryParams.email) &&
            ((!Array.isArray(queryParams.activationCode) && !StringUtils.isNullOrEmpty(queryParams.activationCode)) || (!Array.isArray(queryParams.recoveryToken) && !StringUtils.isNullOrEmpty(queryParams.recoveryToken)))
        ) {
            // If a recoveryToken or activationCode is present (along with an email) in the query params do not check if the current session is valid.
            console.log("Bypassing Session Check...");

            resetSession(session);

            yield put(SET_SESSION(CloneUtils.clone(session)));
        } else {
            // Otherwise check if the current session (i.e. JSESSIONID) is still valid.
            const currentUserResponse: HttpResponse = yield Http.GET("admin/useradmin/currentuser", undefined, Http.JSON_HEADERS);
            if (Http.isStatusOk(currentUserResponse) && !Http.isRedirect(currentUserResponse)) {
                // Current session is valid/authenticated.
                session.isAuthenticated = true;
                session.email = currentUserResponse.data?.email?.toLowerCase() || null;

                // Update the current session.
                yield put(SET_SESSION(CloneUtils.clone(session)));
                yield call(saveSession);
            } else {
                // Current session is invalid/unauthenticated.
                session.isAuthenticated = false;

                // Update the current session.
                yield put(SET_SESSION(CloneUtils.clone(session)));
                yield call(saveSession);
            }
        }

        // get portal banner message and message urgency level
        const [portalBannerUrgency, portalBannerMessage_en, portalBannerMessage_fr]: PromiseSettledResult<any>[] = yield Promise.allSettled([
            BrainCloudPropertiesService.getSystemProperty("portalBannerUrgency"),
            BrainCloudPropertiesService.getSystemProperty(`portalBannerMessage_en`),
            BrainCloudPropertiesService.getSystemProperty(`portalBannerMessage_fr`),
        ]);

        if (portalBannerUrgency.status === "fulfilled" && StringUtils.isTruthy(portalBannerUrgency.value)) {
            applicationInformation.systemMessage.urgencyLevel = Number.parseInt(portalBannerUrgency.value);

            if (portalBannerMessage_en.status === "fulfilled" && !StringUtils.isNullOrEmpty(portalBannerMessage_en.value)) {
                applicationInformation.systemMessage.message.en = portalBannerMessage_en.value;
            }
            if (portalBannerMessage_fr.status === "fulfilled" && !StringUtils.isNullOrEmpty(portalBannerMessage_fr.value)) {
                applicationInformation.systemMessage.message.fr = portalBannerMessage_fr.value;
            }
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }

        // If the user is authenticated, then attempt to complete the login flow (this may fail if the session is expired).
        if (session.isAuthenticated) {
            if (window.location.hash.startsWith("#/implicit/callback")) {
                const externalAuthenticationProviders = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders;

                if (externalAuthenticationProviders.length === 0) {
                    console.error("Invalid SSO Configuration");

                    // Log out the user.
                    yield call(logout, { type: "authentication.logout" });
                } else {
                    session.isAuthenticated = false;

                    delete session.companyId;
                    delete session.companyIdAlias;
                    delete session.appId;
                    delete session.playerId;
                    delete session.playerSummary;

                    if (!session.externalAuthenticationProvider) {
                        session.externalAuthenticationProvider = externalAuthenticationProviders[0];
                    }

                    if (!session.externalAuthType) {
                        session.externalAuthType = externalAuthenticationProviders[0].type;
                    }

                    // Update the current session.
                    yield put(SET_SESSION(CloneUtils.clone(session)));
                    yield call(saveSession);
                }
            } else {
                console.log("User is already authenticated...");

                // Populate the screen settings and available privileges (if an app was already selected).
                yield all([put(SET_SCREEN_SETTINGS([])), put(SET_AVAILABLE_PRIVILEGES([]))]);

                // Complete the login flow.
                yield call(completeLogin, { type: "authentication.completeLogin", payload: { i18n: action.payload.i18n, mode: "full" } });
            }
        } else {
            if (window.location.hash.startsWith("#/implicit/callback")) {
                const externalAuthenticationProviders = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders;

                if (externalAuthenticationProviders.length === 0) {
                    console.error("Invalid SSO Configuration");

                    // Log out the user.
                    yield call(logout, { type: "authentication.logout" });
                } else {
                    session.isAuthenticated = false;

                    delete session.companyId;
                    delete session.companyIdAlias;
                    delete session.appId;
                    delete session.playerId;
                    delete session.playerSummary;

                    if (!session.externalAuthenticationProvider) {
                        session.externalAuthenticationProvider = externalAuthenticationProviders[0];
                    }

                    if (!session.externalAuthType) {
                        session.externalAuthType = externalAuthenticationProviders[0].type;
                    }

                    // Update the current session.
                    yield put(SET_SESSION(CloneUtils.clone(session)));
                    yield call(saveSession);
                }
            } else {
                // If the user is not authenticated, but the auth type is EXTERNAL and we are NOT processing the implicit callback, then automatically log them out.
                // This can happen if the external provider flow was interupted (redirecting to somewhere else for example) and the user then comes back to Portal-X.
                // In this scenario we expect the browser to be hitting the implicit callback to complete the flow but we can't.
                if (session.authType === AuthType.EXTERNAL) {
                    console.log("User is partially authenticated via external provider...");

                    // Log out the user.
                    yield call(logout, { type: "authentication.logout" });
                }
            }

            // If the user is not authenticated, then store the starting hash.
            // It will be restored once they complete the login flow and reach the dashboard.
            if (hashString) {
                console.log("Storing Starting Hash", hashString);

                LocalStorageUtils.setItem("starting_hash", hashString, CookieConsentLevel.STRICTLY_NECESSARY);

                window.history.replaceState({}, document.title, "/");
            } else {
                if (!window.location.hash.startsWith("#/implicit/callback")) {
                    LocalStorageUtils.deleteItem("starting_hash");
                }
            }
        }

        console.log("Done Initializing Application!");

        return true;
    } catch (error: any) {
        console.error("initializeApplication - ERROR", error);

        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (applicationInformation.status !== "error") {
            // Update the current application information.
            applicationInformation.status = "error";
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    } finally {
        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (applicationInformation.loadingInitialState) {
            // Update the current application information.
            applicationInformation.loadingInitialState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    }

    return false;
}

// Populates the portal application's basic redux state (things like the themes, system roles, current user, current company alias, available companies and available apps).
export function* populateBasicState(action: PopulateBasicState) {
    // Define some getters for retrieving redux state.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);
    const getSession = (state: PortalState): Session => CloneUtils.clone(state.session);

    // Get the current application information.
    let applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    // Get the current session.
    let session: Session = yield select(getSession);

    try {
        console.log("Attempting To Load Basic State...", action.payload.mode);

        if (!applicationInformation.loadingBasicState) {
            if (!applicationInformation.loadingBasicState) {
                // Update the current application information.
                applicationInformation.loadingBasicState = true;
                yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
            }
        }

        // Load the target themes for the selected company and company alias.
        if (session.companyId && session.companyIdAlias && session.companyId !== session.companyIdAlias) {
            yield all([call(loadTheme, { type: "theme.loadTheme", payload: { companyId: session.companyId } }), call(loadTheme, { type: "theme.loadTheme", payload: { companyId: session.companyIdAlias } })]);
        } else {
            if (session.companyId) {
                yield call(loadTheme, { type: "theme.loadTheme", payload: { companyId: session.companyId } });
            }
        }

        // Perform population of basic state.
        // The supplied mode (i.e. "login" OR "full") tells us whether to populate the bare minimum (for a newly authenticated user) OR to
        // populate everything (for an already authenticated user who happnes to be refreshing the page or Portal-X itself is triggering it).
        if (action.payload.mode === "login") {
            yield all([call(populateSystemRoles), call(populateCurrentUser, { type: "user.populateCurrentUser", payload: { i18n: action.payload.i18n, basic: true } }), call(populateAvailableCompanies)]);
        } else {
            yield all([call(populateSystemRoles), call(populateCurrentUser, { type: "user.populateCurrentUser", payload: { i18n: action.payload.i18n, basic: false } }), call(populateAvailableCompanies), call(populateCurrentCompanyAlias)]);
            yield call(populateAvailableApps);
        }

        console.log("Done Loading Basic State!");

        return true;
    } catch (error: any) {
        console.error("populateBasicState - ERROR", error);

        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (applicationInformation.status !== "error") {
            // Update the current application information.
            applicationInformation.status = "error";
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    } finally {
        // Get the current application information (the logic above could have modified it).
        applicationInformation = yield select(getApplicationInformation);

        if (applicationInformation.loadingBasicState) {
            // Update the current application information.
            applicationInformation.loadingBasicState = false;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));
        }
    }

    return false;
}

// Clears the portal application's basic redux state (things like the system roles, current user, current company alias, available companies and available apps).
export function* clearBasicState(action: ClearBasicState) {
    try {
        console.log("Attempting To Clear Basic State...", action.payload.mode);

        // Perform clearing of basic state.
        // The supplied mode (i.e. "login" OR "full") tells us whether to clear the bare minimum (for a newly authenticated user) OR to
        // clear everything (for an already authenticated user who happnes to be refreshing the page or Portal-X itself is triggering it).
        if (action.payload.mode === "login") {
            yield all([put(SET_AVAILABLE_COMPANIES([])), put(SET_CURRENT_USER(null)), put(SET_SYSTEM_ROLES([]))]);
        } else {
            yield all([put(SET_AVAILABLE_APPS([])), put(SET_AVAILABLE_COMPANIES([])), put(SET_CURRENT_COMPANY_ALIAS(null)), put(SET_CURRENT_USER(null)), put(SET_SYSTEM_ROLES([]))]);
        }

        console.log("Done Clearing Basic State!");

        return true;
    } catch (error: any) {
        console.error("clearBasicState - ERROR", error);
    }

    return false;
}

// Checks the platforms current server status (if possible).
export function* checkServerStatus(_action?: CheckServerStatus) {
    // Define some getters for retrieving redux state.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);

    // Get the current application information.
    let applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    const { enabled, statusUrl, statusId } = applicationInformation.serverStatusConfiguration;

    if (enabled && statusUrl && statusId) {
        try {
            const serverStatus: ServerStatus = yield StatusService.getServerStatus(statusId);

            // Update the current application information.
            applicationInformation.serverStatus = serverStatus;
            yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

            return true;
        } catch (error: any) {
            console.error("checkServerStatus - ERROR", error);
        }
    }

    return false;
}

// Checks the platforms current portal disabled state.
export function* refreshPortalDisabledState(_action?: RefreshPortalDisabledState) {
    // Define some getters for retrieving redux state.
    const getApplicationInformation = (state: PortalState): ApplicationInformation => CloneUtils.clone(state.applicationInformation);

    // Get the current application information.
    let applicationInformation: ApplicationInformation = yield select(getApplicationInformation);

    try {
        // Retrieve the portalDisabled system property from the server.
        const portalDisabled: string = yield BrainCloudPropertiesService.getSystemProperty("portalDisabled");

        // Update the current application information.
        applicationInformation.disabled = StringUtils.isTruthy(portalDisabled);
        yield put(SET_APPLICATION_INFORMATION(CloneUtils.clone(applicationInformation)));

        return true;
    } catch (error: any) {
        console.error("refreshPortalDisabledState - ERROR", error);
    }

    return false;
}

export default function* root() {
    yield all([
        takeLatest("initialization.initializeApplication", initializeApplication),
        takeLatest("initialization.populateBasicState", populateBasicState),
        takeLatest("initialization.clearBasicState", clearBasicState),
        takeLatest("initialization.checkServerStatus", checkServerStatus),
        takeLatest("initialization.refreshPortalDisabledState", refreshPortalDisabledState),
    ]);
}
