// Import libraries.
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { Language } from "types/models/LanguageInfo";

const availableLanguages: Language[] = [
    {
        label: <Trans>English</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`English`),
        code: "en",
    },
    {
        label: <Trans>French</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`French`),
        code: "fr",
    },
    {
        label: <Trans>Spanish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Spanish`),
        code: "es",
    },
    {
        label: <Trans>Italian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Italian`),
        code: "it",
    },
    {
        label: <Trans>German</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`German`),
        code: "de",
    },
    {
        label: <Trans>Portuguese</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Portuguese`),
        code: "pt",
    },
    {
        label: <Trans>Portuguese, Brazil</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Portuguese (Brazil)`),
        code: "pt_br",
    },
    {
        label: <Trans>Chinese, deprecated</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Chinese`),
        code: "zh",
    },
    {
        label: <Trans>Chinese, Traditional</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Chinese (Traditional)`),
        code: "zh_tw",
    },
    {
        label: <Trans>Chinese, Simplified</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Chinese (Simplified)`),
        code: "zh_cn",
    },
    {
        label: <Trans>Dutch</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Dutch`),
        code: "nl",
    },
    {
        label: <Trans>Japanese</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Japanese`),
        code: "ja",
    },
    {
        label: <Trans>Korean</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Korean`),
        code: "ko",
    },
    {
        label: <Trans>Vietnamese</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Vietnamese`),
        code: "vi",
    },
    {
        label: <Trans>Russian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Russian`),
        code: "ru",
    },
    {
        label: <Trans>Swedish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Swedish`),
        code: "sv",
    },
    {
        label: <Trans>Danish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Danish`),
        code: "da",
    },
    {
        label: <Trans>Finnish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Finnish`),
        code: "fi",
    },
    {
        label: <Trans>Norwegian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Norwegian`),
        code: "no",
    },
    {
        label: <Trans>Turkish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Turkish`),
        code: "tr",
    },
    {
        label: <Trans>Greek</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Greek`),
        code: "el",
    },
    {
        label: <Trans>Indonesian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Indonesian`),
        code: "id",
    },
    {
        label: <Trans>Malay</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Malay`),
        code: "ms",
    },
    {
        label: <Trans>Thai</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Thai`),
        code: "th",
    },
    {
        label: <Trans>Hungarian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Hungarian`),
        code: "hu",
    },
    {
        label: <Trans>Polish</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Polish`),
        code: "pl",
    },
    {
        label: <Trans>Czech</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Czech`),
        code: "cs",
    },
    {
        label: <Trans>Slovak</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Slovak`),
        code: "sk",
    },
    {
        label: <Trans>Ukrainian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Ukrainian`),
        code: "uk",
    },
    {
        label: <Trans>Croatian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Croatian`),
        code: "hr",
    },
    {
        label: <Trans>Catalan</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Catalan`),
        code: "ca",
    },
    {
        label: <Trans>Romanian</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Romanian`),
        code: "ro",
    },
    {
        label: <Trans>Hebrew</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Hebrew`),
        code: "he",
    },
    {
        label: <Trans>Arabic</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Arabic`),
        code: "ar",
    },
    {
        label: <Trans>Punjabi</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Punjabi`),
        code: "pa",
    },
    {
        label: <Trans>Hindi</Trans>,
        labelAsString: (i18n: I18n) => i18n._(t`Hindi`),
        code: "hi",
    },
];

export default availableLanguages;
