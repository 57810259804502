import React from "react";
import { withStyles } from "tss-react/mui";
import classnames from "classnames";

import { Box, ClickAwayListener, Theme, Paper, Popper, PopperPlacementType } from "@mui/material";

interface OWN_PROPS {
    id?: string;
    style?: React.CSSProperties;
    content: React.ReactNode;
    open: boolean;
    onClose: () => void;
    placement?: PopperPlacementType;
    disableFlip?: boolean;
}
interface PROPS extends React.PropsWithChildren<OWN_PROPS> {
    className?: string;
    classes?: Partial<Record<keyof ReturnType<typeof styles>, string>>;
}

interface STATE {
    anchorEl: HTMLElement | null;
    arrowEl: HTMLElement | null;
}

const ChildWithRef = React.memo(
    React.forwardRef((props: React.PropsWithChildren<any>, ref: React.ForwardedRef<unknown>) => {
        const { children } = props;

        return (
            <>
                {React.Children.map(children, (child: React.ReactNode) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child as any, { ref: ref });
                    } else {
                        return null;
                    }
                })}
            </>
        );
    })
);

class PopperWithArrow extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorEl: null,
        arrowEl: null,
    };

    handleArrowRef = (el: HTMLElement | null) => {
        this.setState({ arrowEl: el });
    };

    handleAnchorEl = (el: HTMLElement | null) => {
        this.setState({ anchorEl: el });
    };

    swallowEvent = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    render() {
        const { id, className, style, content, open, onClose, placement = "bottom", disableFlip = false, children } = this.props;
        const { arrowEl, anchorEl } = this.state;

        if (children == null || Array.isArray(children) || !React.isValidElement(children)) {
            console.warn("PoppoerWithArrow may only have a single child component/element as an anchor");

            return <>{children}</>;
        }

        const classes = withStyles.getClasses(this.props);

        return (
            <>
                <ChildWithRef ref={this.handleAnchorEl}>{children}</ChildWithRef>

                <Popper
                    id={id ? `popper-with-arrow-${id}` : undefined}
                    className={classnames(classes.popper, className)}
                    style={style}
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    modifiers={[
                        { name: "flip", enabled: !disableFlip },
                        { name: "arrow", enabled: true, options: { element: arrowEl } },
                        { name: "preventOverflow", enabled: true, options: { boundariesElement: "window", padding: { top: 64, left: 16, right: 16 } } },
                    ]}
                    onMouseDown={this.swallowEvent}
                    onTouchStart={this.swallowEvent}
                >
                    <ClickAwayListener onClickAway={onClose}>
                        <Paper className={classes.contentRoot} onClick={this.swallowEvent}>
                            <span id={"arrow"} className={classes.arrow} ref={this.handleArrowRef} />

                            <Box className={classes.content}>{content}</Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </>
        );
    }
}

const styles = (_theme: Theme, _props: PROPS) =>
    ({
        popper: {
            display: "flex",

            maxWidth: "95%",
            maxHeight: "95%",

            zIndex: 1300,

            '&[data-popper-placement*="bottom"] > div.MuiPaper-root > span#arrow': {
                top: 0,
                left: 0,
                marginTop: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "0 100%",
                },
            },
            '&[data-popper-placement*="top"] > div.MuiPaper-root > span#arrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "100% 0",
                },
            },
            '&[data-popper-placement*="right"] > div.MuiPaper-root > span#arrow': {
                left: 0,
                marginLeft: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "100% 100%",
                },
            },
            '&[data-popper-placement*="left"] > div#.uiPaper-root > span#arrow': {
                right: 0,
                marginRight: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "0 0",
                },
            },
        },
        arrow: {
            position: "absolute",

            width: "1em",
            height: "0.71em",

            boxSizing: "border-box",

            overflow: "hidden",

            "&::before": {
                content: '""',

                display: "block",

                width: "100%",
                height: "100%",

                margin: "auto",

                transform: "rotate(45deg)",

                backgroundColor: "var(--popup-menu-border-color, inherit)",
                borderColor: "var(--popup-menu-border-color, inherit)",

                borderStyle: "solid",
                borderWidth: "0.0625em",

                boxShadow: "var(--standard-box-shadow)",
            },
        },
        contentRoot: {
            flex: "1 1 auto",
            display: "flex",
            overflow: "hidden",

            backgroundColor: "var(--popup-menu-background-color, inherit)",
            color: "var(--popup-menu-color, inherit)",
            borderColor: "var(--popup-menu-border-color, inherit)",

            borderStyle: "solid",
            borderWidth: "0.0625em",
            borderRadius: "0.25em",

            boxShadow: "var(--standard-box-shadow)",
        },
        content: {
            flex: "1 1 auto",

            padding: "0.625em",

            overflow: "auto",
        },
    } as const);

export default withStyles(PopperWithArrow, styles);
