// Import libraries.
import React from "react";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";

interface OWN_PROPS {
    label: React.ReactNode;
    bordered?: boolean;
    textAlign?: "center" | "left" | "right";
    children?: React.ReactNode;
}
interface PROPS extends OWN_PROPS, WithStyles<typeof styles> {}

class Title extends React.PureComponent<PROPS> {
    render() {
        const { label, classes, children, bordered, textAlign } = this.props;

        if (!label) return null;

        return (
            <div className={classnames({ [classes.root]: true, [classes.bordered]: bordered })}>
                <Typography style={{ flex: "1 1 auto", textAlign: textAlign, fontSize: "1.375em", fontWeight: "bold" }}>{label}</Typography>

                {children && <div style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>{children}</div>}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            minHeight: "2.625em",

            paddingLeft: "0.625em",
            paddingRight: "0.625em",

            overflow: "hidden",
            borderColor: "inherit",
        },
        bordered: {
            borderBottomWidth: "0.0625em",
            borderBottomStyle: "solid",
        },
    });

export default withStyles(styles)(Title);
