//Import Libraries.
import React from "react";
import { Trans } from "@lingui/macro";
import { toast } from "utils/Toast";
import classnames from "classnames";

// Import components.
import { Link } from "@mui/material";

//Import Services.
import Services from "./services";

//Import Types.
import TeamInfo from "types/models/TeamInfo";

interface PROPS {
    teamInfo: TeamInfo;
    style?: React.CSSProperties;
    className?: string;
}

class FixBillingLink extends React.Component<PROPS> {
    updateBillingAccount = async () => {
        const { teamInfo } = this.props;

        if (teamInfo.companyId) {
            try {
                const data = await Services.updateBillingAccount(teamInfo.companyId, null);

                if (data.action === "RedirectToUrl") {
                    window.open("https://" + data.url, "_blank");
                } else {
                    toast.success(<Trans>Activation Email Sent</Trans>);
                }
            } catch (error: any) {
                toast.error(error);
            }
        }
    };

    render() {
        const { style, className } = this.props;

        return (
            <Link
                data-id={"fix-now"}
                className={classnames(className)}
                style={{
                    marginLeft: "0.3125em",
                    color: "var(--label-positive-color)",
                    ...style,
                }}
                onClick={this.updateBillingAccount}
            >
                <Trans>Fix Now</Trans>
            </Link>
        );
    }
}

export default FixBillingLink;
