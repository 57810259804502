import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

interface OWN_PROPS {
    alwaysShow?: boolean;
}
interface PROPS extends OWN_PROPS, React.PropsWithoutRef<TooltipProps> {}

const Tooltip = React.forwardRef((props: PROPS, ref: React.ForwardedRef<unknown>) => {
    const { id, alwaysShow, ...otherProps } = props;

    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    const [overflowed, setOverflowed] = useState(true);
    const [rootElement, setRootElement] = useState<HTMLElement | null>(null);

    const resizerDelayTimer = useRef(0);

    const handleRef = useCallback(
        (element: HTMLElement | null) => {
            setRootElement(element);

            if (ref) {
                if (typeof ref === "function") {
                    ref(element);
                } else {
                    ref.current = element;
                }
            }
        },
        [ref]
    );

    const checkOverflow = useCallback((el: HTMLElement) => {
        if (isMounted.current) {
            setOverflowed(el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight);
        }
    }, []);

    useLayoutEffect(() => {
        if (rootElement) checkOverflow(rootElement);
    }, [checkOverflow, rootElement]);

    useEffect(() => {
        if (rootElement) {
            const observer = new ResizeObserver((entries) => {
                if (resizerDelayTimer.current > 0) {
                    window.clearTimeout(resizerDelayTimer.current);
                }

                resizerDelayTimer.current = window.setTimeout(() => {
                    if (entries.length > 0) checkOverflow(entries[0].target as HTMLElement);
                }, 100);
            });

            observer.observe(rootElement);

            return () => {
                observer.disconnect();
            };
        }
    }, [checkOverflow, rootElement]);

    const preventTooltip = useMemo(() => {
        return !alwaysShow && !overflowed;
    }, [alwaysShow, overflowed]);

    return <MuiTooltip id={id} ref={handleRef} {...otherProps} disableHoverListener={preventTooltip} disableTouchListener={preventTooltip} />;
});

export default React.memo(Tooltip);
