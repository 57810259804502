// Import types.
import SupportedLanguage from "types/enums/SupportedLanguage";
import ApiKeyInfo, { processApiKeyInfo } from "types/models/ApiKeyInfo";
import TeamInfo, { processTeamInfo } from "types/models/TeamInfo";
import AppRole from "./AppRole";

export default interface User {
    profileId: string;
    email: string | null;
    fullName: string | null;

    createdAt: Date;
    lastLogin: Date | null;
    badLoginCount: number;

    isEnabled: boolean;
    isActive: boolean;
    isLocked: boolean;
    isSuper: boolean;
    isTeamAdmin: boolean;

    preferredLanguage: SupportedLanguage | null;
    preferredCountry: string | null;
    preferredTimezone: string | null;
    preferredThemeMode: string | null;

    intercomUserHash: string | null;

    appCount?: number;
    appRoles: AppRole[];
    companyIds: string[];
    companies: TeamInfo[];

    twoFactorAuthentication: {
        enabled: boolean;
        pending: boolean;
        authyId: number | null;
        verifyId: string | null;
        countryCode: string | null;
        phoneNumber: string | null;
        lastVerify: {
            [index: string]: Date;
        }[];
    };

    builderApi: {
        enabled: boolean;
        apiKeys: ApiKeyInfo[];
    };

    customData: {
        [index: string]: any;
    } | null;

    sso?: { [index: string]: any };
}

export const processUser = (data: any): User => {
    return {
        profileId: data.profileId,
        email: data.email?.toLowerCase() || null,
        fullName: data.fullName?.trim() || null,

        createdAt: new Date(data.createdAt != null ? data.createdAt : 0),
        lastLogin: data.lastLogin != null ? new Date(data.lastLogin) : null,
        badLoginCount: data.badLoginCount != null ? Number.parseInt(data.badLoginCount) : 0,

        isEnabled: data.isEnabled,
        isActive: data.isActive,
        isLocked: data.isLocked,
        isSuper: data.isSuperUser,
        isTeamAdmin: data.isTeamAdmin,

        preferredLanguage: data.languageCode || null,
        preferredCountry: data.countryCode || null,
        preferredTimezone: data.timezone || null,
        preferredThemeMode: data.themeMode || null,

        intercomUserHash: data.intercomUserHash,

        appCount: data.appCount,
        appRoles: data.appRoles ? (data.appRoles as AppRole[]).sort((a, b) => a.gameName.localeCompare(b.gameName)) : [],
        companyIds: data.companyIds && Array.isArray(data.companyIds) ? data.companyIds : [],
        companies: data.companies && Array.isArray(data.companies) ? data.companies.map(processTeamInfo) : [],

        twoFactorAuthentication: {
            enabled: data.tfaEnabled === true,
            pending: data.tfaPending === true,
            authyId: data.tfaId != null && Number.parseInt(data.tfaId) !== 0 ? Number.parseInt(data.tfaId) : null,
            verifyId: data.tfaTotpSid != null ? data.tfaTotpSid : null,
            countryCode: data.tfaCountryCode ? data.tfaCountryCode : null,
            phoneNumber: data.tfaPhoneNo ? data.tfaPhoneNo : null,
            lastVerify: data.tfaLastVerify ? data.tfaLastVerify : null,
        },

        builderApi: {
            enabled: data.hasApiAccess === true,
            apiKeys: data.apiKeys ? (Array.isArray(data.apiKeys) ? data.apiKeys.map(processApiKeyInfo) : Object.values(data.apiKeys).map(processApiKeyInfo)) : [],
        },

        customData: data.customData || null,

        sso: data.sso,
    };
};
