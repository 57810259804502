// Import libraries.
import React, { ReactNode } from "react";
import { connect } from "react-redux";
import { ClickAwayListener, Theme } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

// Import types.
import PortalState from "types/store";
import User from "types/common/User";
import PlayerSummary from "types/models/PlayerSummaryInfo";
import TeamInfo from "types/models/TeamInfo";
import AppInfo from "types/models/AppInfo";
import PortalRouteDefinition from "types/common/PortalRouteDefinition";

// Import components.
import { Breadcrumbs, Link, Typography } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import IconButton from "components/common/button/IconButton";
import CustomMenu from "components/common/menu";
import NoTeamImage from "components/icons/NoTeamImage";
import UserImagePlaceHolder from "components/common/UserImagePlaceHolder";
import NoAppImage from "components/icons/NoAppImage";
import ImageWrapper from "components/common/ImageWrapper";
import EllipsisInCenter from "components/common/EllipsisInCenter";

// Import icons.
import LinkIcon from "@mui/icons-material/InsertLink";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentCopy from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Import redux actions.
import { SET_RECENTLY_VIEWED } from "store/actions/recentlyViewed";

// Import utilities
import PortalRouteUtils from "utils/PortalRoutes";
import RecentlyViewedUtils from "utils/RecentlyViewed";
import ClipboardUtils from "utils/Clipboard";
import StringUtils from "utils/String";

// Import screen data collection.
import { ScreenData, getScreenDataForId } from "assets/data/screens";

interface STATE_PROPS {
    currentUser: User | null;
    recentlyViewed: string[];
}
interface DISPATCH_PROPS {
    setRecentlyViewed: (recentlyViewed: string[]) => void;
}
interface OWN_PROPS {
    definitions: PortalRouteDefinition[];
    teamInfo: TeamInfo | null;
    appInfo: AppInfo | null;
    playerSummary: PlayerSummary | null;
    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";
    onToggleBannerOpen: (mode: "team" | "app" | "player", open?: boolean, saveToLocalStorage?: boolean) => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps {}

const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        currentUser: state.currentUser,
        recentlyViewed: state.recentlyViewed,
    };
};

const mapDispatchToProps = (dispatch: Function): DISPATCH_PROPS => {
    return {
        setRecentlyViewed: (recentlyViewed: string[]) => dispatch(SET_RECENTLY_VIEWED(recentlyViewed)),
    };
};

interface STATE {
    anchorE1: any;
    anchorE2: any;
    screenInfo: ScreenData | null;
    collapseBreadcrumbs: boolean;
    showOptions: boolean;
}

class BreadcrumbBar extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        anchorE1: null,
        anchorE2: null,
        screenInfo: null,
        collapseBreadcrumbs: false,
        showOptions: false,
    };

    private breadcrumbSpanRef = React.createRef<HTMLDivElement>();
    private breadcrumbsRef = React.createRef<HTMLElement>();

    componentDidMount() {
        this.updateRecentlyViewed();
        this.handleBreadcrumbsResize();
        this.getScreenInfo();
    }

    componentDidUpdate(prevProps: PROPS) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.updateRecentlyViewed();
            this.handleBreadcrumbsResize();
            this.getScreenInfo();
        }
        if (prevProps.bannerOpen !== this.props.bannerOpen) {
            this.setState({ showOptions: false });
        }
        window.addEventListener("resize", this.handleBreadcrumbsResize);
    }

    getScreenInfo = () => {
        const screenInfo = getScreenDataForId(this.props.location.pathname);

        this.setState({ screenInfo });
    };

    handleBreadcrumbsResize = () => {
        if (this.breadcrumbSpanRef.current && this.breadcrumbsRef.current) {
            const breadcrumbSpanWidth = this.breadcrumbSpanRef.current.offsetWidth;
            const breadcrumbsWidth = this.breadcrumbsRef.current.offsetWidth;

            if (breadcrumbsWidth >= breadcrumbSpanWidth) this.setState({ collapseBreadcrumbs: true });
            else this.setState({ collapseBreadcrumbs: false });
        }
    };

    openBreadcrumbsMenu = (event: any) => this.setState({ anchorE1: event.currentTarget });

    closeBreadcrumbsMenu = () => this.setState({ anchorE1: null });

    openShareInfoMenu = (event: any) => this.setState({ anchorE2: event.currentTarget });

    closeShareInfoMenu = () => this.setState({ anchorE2: null });

    toggleTeamAndAppBanner = () => {
        const { location, bannerOpen, bannerMode } = this.props;

        if (!location.pathname.startsWith("/super") && !["/team/dashboard", "/app/dashboard"].includes(location.pathname)) {
            this.props.onToggleBannerOpen(bannerMode, !bannerOpen, true);
        } else {
            this.props.onToggleBannerOpen(bannerMode, !bannerOpen);
        }
    };

    generatePathToCurrentPage = (currentRoutesInPath: PortalRouteDefinition[]) => {
        return currentRoutesInPath.map((item) => item.label(this.props.i18n)).join(" > ");
    };

    updateRecentlyViewed = () => {
        const { location, definitions, currentUser, recentlyViewed } = this.props;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        if (currentUser) {
            let updatedRecentlyViewed = [...recentlyViewed];

            if (updatedRecentlyViewed.includes(currentPath)) {
                updatedRecentlyViewed.splice(updatedRecentlyViewed.indexOf(currentPath), 1);
                updatedRecentlyViewed.push(currentPath);
            } else {
                if (updatedRecentlyViewed.length === 10) {
                    updatedRecentlyViewed = updatedRecentlyViewed.slice(1);
                }

                updatedRecentlyViewed.push(currentPath);
            }

            RecentlyViewedUtils.setRecentlyViewed(currentUser.profileId, updatedRecentlyViewed);

            this.props.setRecentlyViewed(updatedRecentlyViewed);
        }
    };

    getOption = (bannerType: "team" | "app" | "player", isSelected: boolean = false) => {
        const { classes, teamInfo, appInfo, playerSummary } = this.props;

        const onOptionClick = () => {
            if (!isSelected) {
                this.props.onToggleBannerOpen(bannerType);
            }
            this.setState({ showOptions: !this.state.showOptions });
        };

        const optionData: Record<string, { title: ReactNode; color: string; name: string; imageUrl: string | null; fallback: ReactNode; textToCopy: string; updatedAt?: Date; tooltip: ReactNode }> = {
            team: {
                title: <Trans>Team</Trans>,
                color: "var(--navigation-team-context-color, inherit)",
                name: teamInfo?.companyName || "---",
                imageUrl: teamInfo?.imageUrl ? `files/portal/c/${teamInfo.companyId}${teamInfo.imageUrl}` : null,
                fallback: <NoTeamImage />,
                textToCopy: `${teamInfo?.companyName} (${teamInfo?.accountNumber})`,
                updatedAt: teamInfo?.updatedAt,
                tooltip: <Trans>Copy Team Name and Account Number</Trans>,
            },
            app: {
                title: <Trans>App</Trans>,
                color: "var(--navigation-app-context-color, inherit)",
                name: appInfo?.appName || "---",
                imageUrl: appInfo?.imageUrl ? `files/portal/g/${appInfo.appId}${appInfo.imageUrl}` : null,
                fallback: <NoAppImage />,
                textToCopy: `${appInfo?.appName} (${appInfo?.appId})`,
                updatedAt: appInfo?.updatedAt,
                tooltip: <Trans>Copy App Name and ID</Trans>,
            },
            player: {
                title: <Trans>User</Trans>,
                color: "var(--navigation-user-context-color, inherit)",
                name: playerSummary ? playerSummary.name ?? playerSummary.email ?? playerSummary.playerId : "---",
                imageUrl: playerSummary?.pictureUrl || null,
                fallback: <UserImagePlaceHolder data={playerSummary} style={{ fontSize: "0.5em" }} />,
                textToCopy: playerSummary?.playerId || "",
                tooltip: <Trans>Copy ProfileId</Trans>,
            },
        };

        const option = (
            <div className={classes.menuOptions} onClick={onOptionClick}>
                <div style={{ width: "1.5em", height: "1.5em" }}>{isSelected && <ArrowDropDownIcon style={{ color: optionData[bannerType].color }} />}</div>
                <Typography className={classes.name} style={{ color: optionData[bannerType].color }}>
                    <Trans>{optionData[bannerType].title}</Trans>
                </Typography>

                <ImageWrapper style={{ width: "1.5em", height: "1.5em" }} src={optionData[bannerType].imageUrl} fallback={optionData[bannerType].fallback} disableCache={optionData[bannerType].updatedAt} />

                <div className={classes.value}>
                    <EllipsisInCenter id={`${bannerType}-info`} style={{ fontSize: "0.85em", fontWeight: "bold" }} value={optionData[bannerType].name} customTooltip={optionData[bannerType].textToCopy} />
                </div>

                <Tooltip alwaysShow arrow title={optionData[bannerType].tooltip}>
                    <IconButton id={`copy-${bannerType}-info`} onClick={() => ClipboardUtils.writeText(optionData[bannerType].textToCopy)} style={{ height: "1.375em", marginLeft: "auto", color: optionData[bannerType].color }}>
                        <ContentCopy />
                    </IconButton>
                </Tooltip>
            </div>
        );

        return option;
    };

    handleClickAway = () => this.setState({ showOptions: false });

    render() {
        const { classes, history, location, definitions, bannerOpen, bannerMode, appInfo, playerSummary } = this.props;
        const { anchorE1, anchorE2, collapseBreadcrumbs, screenInfo, showOptions } = this.state;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        const currentRoutesInPath = PortalRouteUtils.getPortalRouteDefinitionsInPath(definitions, currentPath);

        const breadcrumbs = screenInfo?.breadcrumbs || [];
        const options = ["team", "app", "player"].filter((mode) => {
            if (mode === bannerMode || (mode === "player" && !playerSummary)) {
                return false;
            }
            return true;
        });

        const isUserPath = location.pathname.startsWith("/user");

        return (
            <div id={"breadcrumb-bar"} className={classes.root}>
                <span ref={this.breadcrumbSpanRef} style={{ flex: "1 1 auto", display: "flex", flexDirection: "column", overflow: "hidden" }}>
                    <Breadcrumbs
                        ref={this.breadcrumbsRef}
                        aria-label="breadcrumb"
                        style={{ flex: "1 0 auto", maxHeight: collapseBreadcrumbs ? 0 : undefined, width: "fit-content", alignItems: "center" }}
                        className={classes.breadcrumbs}
                        maxItems={10}
                        separator={<ChevronRightIcon />}
                    >
                        {isUserPath && appInfo && (
                            <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                                <ImageWrapper
                                    style={{ width: "1.75em", height: "1.75em" }}
                                    src={!StringUtils.isNullOrEmpty(appInfo?.imageUrl) ? "files/portal/g/" + appInfo?.appId + appInfo?.imageUrl : null}
                                    fallback={<NoAppImage />}
                                    disableCache={appInfo?.updatedAt || null}
                                />

                                <Tooltip alwaysShow arrow title={<Typography>{`${appInfo.appName} (${appInfo.appId})`}</Typography>}>
                                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold", marginLeft: "0.3125em", marginRight: "0.3125em" }} noWrap>
                                        {appInfo.appName}
                                    </Typography>
                                </Tooltip>
                            </span>
                        )}

                        {breadcrumbs.map((item, idx) => {
                            if (idx !== breadcrumbs.length - 1) {
                                if (item.route != null) {
                                    return (
                                        <Link key={idx} rel="noreferrer" onClick={() => history.push(item.route as string)}>
                                            {item.label}
                                        </Link>
                                    );
                                } else {
                                    return <Typography key={idx}>{item.label}</Typography>;
                                }
                            } else
                                return (
                                    <span key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: ".5em" }}>
                                        <Typography key={idx} style={{ fontWeight: "bold" }}>
                                            {item.label}
                                        </Typography>

                                        <Tooltip alwaysShow title={<Trans>Copy...</Trans>}>
                                            <IconButton id={"share-page-info"} type={"neutral"} onClick={this.openShareInfoMenu}>
                                                <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                );
                        })}
                    </Breadcrumbs>

                    <Breadcrumbs aria-label="collapsed-breadcrumb" style={{ maxHeight: !collapseBreadcrumbs ? 0 : undefined }} className={classes.breadcrumbs} maxItems={10} separator={<ChevronRightIcon />}>
                        {isUserPath && appInfo && (
                            <span style={{ flex: "0 0 auto", display: "flex", alignItems: "center" }}>
                                <ImageWrapper
                                    style={{ width: "1.75em", height: "1.75em" }}
                                    src={!StringUtils.isNullOrEmpty(appInfo?.imageUrl) ? "files/portal/g/" + appInfo?.appId + appInfo?.imageUrl : null}
                                    fallback={<NoAppImage />}
                                    disableCache={appInfo?.updatedAt || null}
                                />

                                <Tooltip alwaysShow arrow title={<Typography>{`${appInfo.appName} (${appInfo.appId})`}</Typography>}>
                                    <Typography style={{ flex: "1 1 auto", fontWeight: "bold", marginLeft: "0.3125em", marginRight: "0.3125em" }} noWrap>
                                        {appInfo.appName}
                                    </Typography>
                                </Tooltip>
                            </span>
                        )}

                        {breadcrumbs.length > 0 && (
                            <Link rel="noreferrer" onClick={() => history.push(breadcrumbs[0].route as string)}>
                                {breadcrumbs[0].label}
                            </Link>
                        )}

                        {breadcrumbs.length > 2 && (
                            <IconButton id={"more-actions"} onClick={this.openBreadcrumbsMenu}>
                                <MoreHorizIcon />
                            </IconButton>
                        )}

                        {breadcrumbs.length > 1 && (
                            <span style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: ".5em" }}>
                                <Typography style={{ fontWeight: "bold" }}>{breadcrumbs[breadcrumbs.length - 1].label}</Typography>

                                <Tooltip alwaysShow title={<Trans>Copy...</Trans>}>
                                    <IconButton id={"share-page-info"} type={"neutral"} onClick={this.openShareInfoMenu}>
                                        <LinkIcon style={{ transform: "rotate(-45deg)" }} />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        )}
                    </Breadcrumbs>
                </span>

                {collapseBreadcrumbs && breadcrumbs.length > 2 && (
                    <CustomMenu
                        anchorElement={anchorE1}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={Boolean(anchorE1)}
                        onClose={this.closeBreadcrumbsMenu}
                        menuItems={breadcrumbs.slice(1, breadcrumbs.length - 1).map((item, idx) => {
                            return {
                                id: `${idx}`,
                                label: <Typography style={{ opacity: item.route == null ? 0.5 : undefined }}>{item.label}</Typography>,
                                onClick: () => {
                                    if (item.route != null) {
                                        history.push(item.route);
                                    }

                                    this.closeBreadcrumbsMenu();
                                },
                            };
                        })}
                    />
                )}

                <CustomMenu
                    id={"share-page-info"}
                    anchorElement={anchorE2}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    open={Boolean(anchorE2)}
                    onClose={this.closeShareInfoMenu}
                    menuItems={[
                        {
                            id: "copy-page-url",
                            label: <Trans>Copy page URL</Trans>,
                            onClick: () => {
                                ClipboardUtils.writeText(window.location.href);
                                this.closeShareInfoMenu();
                            },
                        },
                        {
                            id: "copy-path",
                            label: <Trans>Copy path to current page</Trans>,
                            onClick: () => {
                                ClipboardUtils.writeText(this.generatePathToCurrentPage(currentRoutesInPath));
                                this.closeShareInfoMenu();
                            },
                        },
                    ]}
                />

                {!location.pathname.startsWith("/super") && !bannerOpen && (
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <div className={classes.microBlade} style={{ borderRadius: !showOptions ? "0.25em" : "0.25em 0.25em 0  0" }}>
                            <div>
                                {this.getOption(bannerMode, true)}
                                {options.length > 0 && (
                                    <div
                                        className={classnames({ [classes.microBlade]: true, [classes.closed]: !showOptions })}
                                        style={{ position: "absolute", zIndex: 1, flexDirection: "column", marginLeft: showOptions ? "-0.05em" : "", borderRadius: showOptions ? "0 0 0.25em 0.25em" : "" }}
                                    >
                                        {options.map((mode, idx) => (
                                            <React.Fragment key={idx}>{this.getOption(mode as "team" | "app" | "player", false)}</React.Fragment>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ClickAwayListener>
                )}

                {!location.pathname.startsWith("/super") && (
                    <Tooltip alwaysShow arrow title={bannerOpen ? <Trans>Hide team/app/profile banner</Trans> : <Trans>Show team/app/profile banner</Trans>}>
                        <IconButton id={"toggle-banner"} type={"neutral"} onClick={this.toggleTeamAndAppBanner}>
                            {bannerOpen ? <ChevronUpIcon style={{ pointerEvents: "none" }} /> : <ChevronDownIcon style={{ pointerEvents: "none" }} />}
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            minHeight: "2.1252em",
            width: "100%",
            padding: "0.2em 0.625em",
            overflow: "hidden",
            flex: "0 0 auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "var(--primary-breadcrumbs-background-color, inherit)",
            color: "var(--primary-breadcrumbs-color, inherit)",
            borderColor: "var(--primary-breadcrumbs-border-color, inherit)",
            borderTopStyle: "solid",
            borderTopWidth: "0.0625em",
            borderBottomStyle: "solid",
            borderBottomWidth: "0.0625em",
        },
        breadcrumbs: {
            color: "inherit",
            overflow: "hidden",
            "& .MuiBreadcrumbs-ol": {
                flexWrap: "nowrap",
                whiteSpace: "nowrap",
                "& .MuiBreadcrumbs-separator": {
                    margin: "0 .3125em",
                },
                "& .MuiBreadcrumbs-li *": {
                    color: "inherit",
                },
            },
        },
        microBlade: {
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            backgroundColor: "var(--secondary-background-color, inherit)",
            color: "var(--secondary-color, inherit)",
            border: "0.0625em solid var(--secondary-border-color, inherit)",
            maxHeight: "calc(4em + var(--scrollbar-size, 0))",
            transition: "max-height 0.2s linear, border-bottom-width 0.2s linear",
            cursor: "pointer",

            "@media(max-width: 1070px)": {
                display: "none",
            },

            "& > *:not(:first-child)": {
                borderTop: "inherit",
            },
        },
        menuOptions: {
            display: "flex",
            gap: "0.125em",
            alignItems: "center",
        },
        name: {
            fontWeight: "bold",
            fontSize: "0.8em",
            width: "3em",
            textAlign: "center",
        },
        value: {
            width: "10em",
            borderRight: "0.0625em solid var(--secondary-border-color, inherit)",
        },
        closed: {
            maxHeight: 0,
            border: "0 !important",
            borderRadius: 0,
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withI18n()(withStyles(styles)(BreadcrumbBar))));
