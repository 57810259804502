// Import libraries.
import React from "react";
import { connect } from "react-redux";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withFormValidator, withFormValidatorProps } from "framework/formValidator";
import { withI18n, withI18nProps } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import QueryString from "query-string";

// Import types.
import AuthType from "types/enums/AuthType";
import TwoFactorType from "types/enums/TwoFactorType";
import LoginStatus from "types/enums/LoginStatus";
import PortalState from "types/store";
import ClientInformation from "types/common/ClientInformation";
import ApplicationInformation from "types/common/ApplicationInformation";
import ExternalAuthenticationProvider from "types/common/ExternalAuthenticationProvider";
import Session, { resetSession } from "types/common/Session";
import { TextFieldOptions } from "components/common/form/fields/TextField";

// Import redux actions.
import { SET_SESSION } from "store/actions/session";

// Import components.
import { Chip, Divider, Link, Typography } from "@mui/material";
import FieldWrapper from "components/common/form/FieldWrapper";
import Button from "components/common/button/Button";

// Import icons.
import ExternalLoginIcon from "@mui/icons-material/Lock";

// Import utilities.
import * as Patterns from "utils/Patterns";
import Http, { encodeQueryParameters, HttpResponse } from "utils/networking/Http";
import CloneUtils from "utils/Clone";
import StringUtils from "utils/String";
import CookieConsentUtils from "utils/CookieConsent";

interface STATE_PROPS {
    clientInformation: ClientInformation;
    applicationInformation: ApplicationInformation;
    session: Session;
}
interface DISPATCH_PROPS {
    setSession: (session: Session) => void;
    saveSession: () => void;
}
interface OWN_PROPS {
    isRestricted: boolean;

    onComplete: (requires2FA: boolean, type: TwoFactorType | null) => void;
    onRegister: () => void;
    onRecover: () => void;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, withI18nProps, withFormValidatorProps {}

interface STATE {
    email: string;
    password: string;

    loginEmailPasswordStatus: LoginStatus | null;
    loginExternalStatus: LoginStatus | null;

    remainingLoginAttempts: number | null;
    requires2FA: boolean;
}

const mapStateToProps = (state: PortalState) => {
    return {
        clientInformation: state.clientInformation,
        applicationInformation: state.applicationInformation,
        session: state.session,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        setSession: (session: Session) => dispatch(SET_SESSION(session)),
        saveSession: () => dispatch({ type: "session.saveSession" }),
    };
};

class Login extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        email: this.props.session.email || "",
        password: "",

        loginEmailPasswordStatus: null,
        loginExternalStatus: null,

        remainingLoginAttempts: null,
        requires2FA: false,
    };
    private loginEmailPasswordButtonRef = React.createRef<HTMLElement>();

    componentDidMount() {
        const { session, isRestricted } = this.props;

        // Only proceed if the user has completed the Cookie Consent.
        if (CookieConsentUtils.isUserAccepted()) {
            // Extract any URI parameters that may have been supplied.
            let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
            if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
            const queryParams = queryString ? QueryString.parse(queryString) : {};

            if (window.location.hash.startsWith("#/implicit/callback")) {
                console.log("Processing implicit callback from external provider...", session.externalAuthType);

                this.onImplicitCallback();
            } else {
                if (session.autoAttemptExternalAuth) {
                    console.log("Triggering auto-authentication via external provider...", session.externalAuthType);

                    this.onLoginExternal(session.externalAuthType, true);
                } else {
                    if (!Array.isArray(queryParams.email) && !StringUtils.isNullOrEmpty(queryParams.email)) {
                        // If the email argument is present then set the current email (for email/password login).
                        this.setState({ email: queryParams.email || "" });
                    }

                    // If the 'isRestricted' property is TRUE, then the user's access to Portal-X has been denied (based on the current restriction conditions).
                    if (isRestricted) {
                        if (session.authType === AuthType.EMAIL) {
                            this.setState({ loginEmailPasswordStatus: LoginStatus.RESTRICTED });
                        }

                        if (session.authType === AuthType.EXTERNAL) {
                            this.setState({ loginExternalStatus: LoginStatus.RESTRICTED });
                        }
                    }
                }
            }
        }
    }

    handleChange = (name: string, value: any) => {
        switch (name) {
            case "email":
                this.setState({ email: value.trim() });
                break;
            case "password":
                this.setState({ password: value });
                break;
            default:
            // Do nothing.
        }
    };

    handleKeyPress = (name: string, key: string) => {
        if (["email", "password"].includes(name) && key === "Enter") {
            this.loginEmailPasswordButtonRef.current?.focus();

            this.onLoginEmailPassword();
        }
    };

    onLoginEmailPassword = async () => {
        const { bcFormValidator, clientInformation, session } = this.props;
        const { email, password } = this.state;

        if (!bcFormValidator.validate()) return;

        console.log("Attempting to authenticate via email/password...");

        const updatedSession = CloneUtils.clone(session) as Session;

        // Reset the session.
        resetSession(updatedSession);

        // Update the session.
        updatedSession.authType = AuthType.EMAIL;
        delete updatedSession.externalAuthType;
        updatedSession.email = email.toLowerCase();
        delete updatedSession.externalAuthenticationProvider;
        delete updatedSession.autoAttemptExternalAuth;
        delete updatedSession.autoCompleteCompanySelection;

        // Update the session in the redux store.
        this.props.setSession(CloneUtils.clone(updatedSession) as Session);
        this.props.saveSession();

        this.setState({ loginEmailPasswordStatus: LoginStatus.PENDING }, async () => {
            // Construct the basic authentication form using the supplied credentials.
            const authenticationForm = {
                j_username: email,
                j_password: JSON.stringify({ password: password, browserId: clientInformation.browserClientId }),
            };

            // Perform the actual login via the j_spring_security_check endpoint.
            const loginResponse = await Http.POST("j_spring_security_check", null, encodeQueryParameters(authenticationForm), { "Content-Type": "application/x-www-form-urlencoded" });

            if (Http.isStatusOk(loginResponse)) {
                if (Http.isRedirect(loginResponse, "tfa_verify")) {
                    console.log("Re-directing to Verify MFA (Verify)...");

                    // If we are redirected to 'tfa_verify', then the user passed basic authentication but requires 2FA verification (Verify).
                    this.setState({ loginEmailPasswordStatus: LoginStatus.AUTHENTICATED, requires2FA: true }, () => {
                        this.props.onComplete(this.state.requires2FA, TwoFactorType.VERIFY);
                    });
                } else if (Http.isRedirect(loginResponse, "tfa_authy")) {
                    console.log("Re-directing to Verify MFA (Authy)...");

                    // If we are redirected to 'tfa_authy', then the user passed basic authentication but requires 2FA verification (Authy).
                    this.setState({ loginEmailPasswordStatus: LoginStatus.AUTHENTICATED, requires2FA: true }, () => {
                        this.props.onComplete(this.state.requires2FA, TwoFactorType.AUTHY);
                    });
                } else if (Http.isRedirect(loginResponse, "loginfailed") || Http.isRedirect(loginResponse, "login")) {
                    console.log("Login failed...");

                    // If we are redirected to 'loginfailed', then the user failed basic authentication.
                    this.processLoginFailure(loginResponse, null, false);

                    // Clear out the 'authType' and 'externalAuthType' fields fromn the session.
                    updatedSession.authType = null;
                } else {
                    console.log("Login succeeded...");

                    // Otherwise, the user passed basic authentication and does NOT require 2FA verification (their device is already trusted).
                    this.setState({ loginEmailPasswordStatus: LoginStatus.AUTHENTICATED, requires2FA: false }, () => {
                        this.props.onComplete(this.state.requires2FA, null);
                    });
                }
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.UNKNOWN_ERROR, requires2FA: false });
            }
        });
    };

    onLoginExternal = (type?: string | null, auto?: boolean) => {
        const { applicationInformation, session } = this.props;

        if (auto) {
            console.log("Attempting to auto-authenticate via external provider...");
        } else {
            console.log("Attempting to authenticate via external provider...");
        }

        const availableExternalAuthenticationProviders = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders;

        let externalAuthenticationProvider = availableExternalAuthenticationProviders.find((item) => item.type === type) || null;

        if (!externalAuthenticationProvider && auto) {
            externalAuthenticationProvider = availableExternalAuthenticationProviders[0];
        }

        if (!externalAuthenticationProvider || !externalAuthenticationProvider.params.ssoUrl) {
            console.error("Invalid SSO Configuration");

            return;
        }

        const updatedSession = CloneUtils.clone(session) as Session;

        // Reset the session.
        resetSession(updatedSession);

        // Update the session.
        updatedSession.authType = AuthType.EXTERNAL;
        updatedSession.externalAuthType = type;
        updatedSession.email = null;
        updatedSession.externalAuthenticationProvider = externalAuthenticationProvider;
        if (auto) {
            updatedSession.autoAttemptExternalAuth = false;
            updatedSession.autoCompleteCompanySelection = true;
        } else {
            delete updatedSession.autoAttemptExternalAuth;
            delete updatedSession.autoCompleteCompanySelection;
        }

        // Update the session in the redux store.
        this.props.setSession(CloneUtils.clone(updatedSession) as Session);
        this.props.saveSession();

        this.setState({ loginExternalStatus: LoginStatus.PENDING }, async () => {
            if (externalAuthenticationProvider) {
                // Redirect the browser to the SSO provider.
                if (externalAuthenticationProvider.params.redirectUrl) {
                    window.location.href = "" + externalAuthenticationProvider.params.ssoUrl + "?" + encodeQueryParameters({ redirect: externalAuthenticationProvider.params.redirectUrl });
                } else {
                    window.location.href = "" + externalAuthenticationProvider.params.ssoUrl;
                }
            }
        });
    };

    onImplicitCallback = async () => {
        const { session, applicationInformation } = this.props;

        const updatedSession = CloneUtils.clone(session) as Session;

        if (updatedSession.authType !== AuthType.EXTERNAL) {
            updatedSession.authType = AuthType.EXTERNAL;
        }

        if (!updatedSession.externalAuthenticationProvider) {
            const availableExternalAuthenticationProviders = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders;

            updatedSession.externalAuthType = availableExternalAuthenticationProviders[0].type;
            updatedSession.externalAuthenticationProvider = availableExternalAuthenticationProviders[0];
        } else {
            updatedSession.externalAuthType = updatedSession.externalAuthenticationProvider.type;
        }

        // Extract any URI parameters that may have been supplied.
        // The URI parameters SHOULD contain both the "normal" redirect URL (i.e. '/tfa/welcome') and
        // the principal associated with the SSO user (if successful).
        let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
        if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
        const queryParams = queryString ? QueryString.parse(queryString) : {};

        // Get the principal from the query parameters.
        if (queryParams.principal && !Array.isArray(queryParams.principal) && !StringUtils.isNullOrEmpty(queryParams.principal)) {
            updatedSession.email = queryParams.principal.toLowerCase();
        } else {
            updatedSession.email = null;
        }

        // Get any error from the query parameters.
        let error: string | null = null;
        if (!Array.isArray(queryParams.error) && !StringUtils.isNullOrEmpty(queryParams.error)) {
            error = queryParams.error;
        }

        this.setState({ loginExternalStatus: LoginStatus.PENDING });

        // Update the session in the redux store.
        this.props.setSession(CloneUtils.clone(updatedSession) as Session);
        this.props.saveSession();

        window.history.replaceState({}, document.title, "/");

        if (updatedSession.email) {
            if (queryParams.targetUrl === "/tfa/welcome") {
                const tfaWelcomeResponse = await Http.GET("tfa/welcome");

                if (Http.isStatusOk(tfaWelcomeResponse)) {
                    if (Http.isRedirect(tfaWelcomeResponse, "tfa_verify")) {
                        console.log("Re-directing to Verify MFA (Verify)...");

                        // If we are redirected to 'tfa_verify', then the user passed external authentication but requires 2FA verification (Verify).
                        this.setState({ loginExternalStatus: LoginStatus.AUTHENTICATED, requires2FA: true }, () => {
                            this.props.onComplete(this.state.requires2FA, TwoFactorType.VERIFY);
                        });
                    } else if (Http.isRedirect(tfaWelcomeResponse, "tfa_authy")) {
                        console.log("Re-directing to Verify MFA (Authy)...");

                        // If we are redirected to 'tfa_authy', then the user passed external authentication but requires 2FA verification (Authy).
                        this.setState({ loginExternalStatus: LoginStatus.AUTHENTICATED, requires2FA: true }, () => {
                            this.props.onComplete(this.state.requires2FA, TwoFactorType.AUTHY);
                        });
                    } else if (Http.isRedirect(tfaWelcomeResponse, "loginfailed")) {
                        console.log("Login failed...");

                        // If we are redirected to 'loginfailed', then the user failed basic authentication.
                        this.processLoginFailure(tfaWelcomeResponse, error, true);

                        // Clear out the 'authType' and 'externalAuthType' fields fromn the session.
                        updatedSession.authType = null;
                        delete updatedSession.externalAuthType;
                    } else {
                        console.log("Login succeeded...");

                        // Otherwise, the user passed external authentication and does NOT require 2FA verification (their device is already trusted).
                        this.setState({ loginExternalStatus: LoginStatus.AUTHENTICATED, requires2FA: false }, () => {
                            this.props.onComplete(this.state.requires2FA, null);
                        });
                    }
                } else {
                    this.setState({ loginExternalStatus: LoginStatus.UNKNOWN_ERROR, requires2FA: false });
                }
            } else if (queryParams.targetUrl === "/loginfailed") {
                console.log("Login failed...");

                // If the targetUrl is 'loginfailed', then the user failed basic authentication.
                this.processLoginFailure(null, error, true);

                // Clear out the 'authType' and 'externalAuthType' fields fromn the session.
                updatedSession.authType = null;
                delete updatedSession.externalAuthType;
            } else {
                console.log("Login succeeded...");

                // Otherwise, the user passed external authentication and does NOT require 2FA verification (their device is already trusted).
                this.setState({ loginExternalStatus: LoginStatus.AUTHENTICATED, requires2FA: false }, () => {
                    this.props.onComplete(this.state.requires2FA, null);
                });
            }
        } else {
            if (error) {
                console.log("Login failed...");

                // If there is an 'error' query parameter present, then the user failed basic authentication.
                this.processLoginFailure(null, error, true);

                // Clear out the 'authType' and 'externalAuthType' fields fromn the session.
                updatedSession.authType = null;
                delete updatedSession.externalAuthType;
            } else {
                this.setState({ loginExternalStatus: LoginStatus.UNKNOWN_USER, requires2FA: false });
            }
        }

        delete updatedSession.externalAuthenticationProvider;
        delete updatedSession.autoAttemptExternalAuth;

        // Update the session in the redux store.
        this.props.setSession(CloneUtils.clone(updatedSession) as Session);
        this.props.saveSession();
    };

    processLoginFailure = (response: HttpResponse | null, error: string | null, isExternal: boolean) => {
        console.log("Processing Login Failure...", response, error);

        // If we are redirected to 'loginfailed', then the user failed external authentication.
        const responseData: string = response?.data || "";
        const lastException = responseData.match(/lastException: "([^"]+)"/);
        const lastExceptionCause = lastException && lastException.length > 1 && lastException[1].trim().length > 0 ? lastException[1].trim() : error && error.trim().length > 0 ? error.trim() : "Unknown Error";

        // Attempt to determine the cause of the authentication failure.
        const isNotConfigured = lastExceptionCause.match(/Portal is not configured.*/);
        const isUserLocked = lastExceptionCause.match(/User is locked/);
        const isUnknownUser = lastExceptionCause.match(/Unknown user/);
        const isBadPassword = lastExceptionCause.match(/Bad password - (\d+) tries left/);
        const isUserMustLoginWithSSO = lastExceptionCause.match(/SSO user must login with SSO/);
        const isUserMustNotHaveSSOCredentials = lastExceptionCause.match(/Portal SSO Admin login - user must not have SSO credentials/);

        if (isNotConfigured) {
            // System is not configured for authentication type.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.NOT_CONFIGURED });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.NOT_CONFIGURED });
            }
        } else if (isUserLocked) {
            // User's account is locked.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.USER_IS_LOCKED });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.USER_IS_LOCKED });
            }
        } else if (isUnknownUser) {
            // User's account does not exist.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.UNKNOWN_USER });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.UNKNOWN_USER });
            }
        } else if (isBadPassword) {
            // User's password was invalid.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.BAD_PASSWORD, remainingLoginAttempts: Number(isBadPassword[1]) });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.BAD_PASSWORD, remainingLoginAttempts: Number(isBadPassword[1]) });
            }
        } else if (isUserMustLoginWithSSO) {
            // User must login via SSO.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.MUST_LOGIN_WITH_SSO });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.MUST_LOGIN_WITH_SSO });
            }
        } else if (isUserMustNotHaveSSOCredentials) {
            // User must not have SSO credentials.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.MUST_NOT_HAVE_SSO_CREDENTIALS });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.MUST_NOT_HAVE_SSO_CREDENTIALS });
            }
        } else {
            // Unknown/unexpected failure.
            if (isExternal) {
                this.setState({ loginExternalStatus: LoginStatus.UNKNOWN_ERROR });
            } else {
                this.setState({ loginEmailPasswordStatus: LoginStatus.UNKNOWN_ERROR });
            }
        }
    };

    render() {
        const { bcFormValidator, i18n, classes, applicationInformation, session } = this.props;
        const { email, password, loginEmailPasswordStatus, loginExternalStatus, remainingLoginAttempts } = this.state;

        let adminLoginOverride = false;

        let queryString = window.location.href.indexOf("?") >= 0 ? window.location.href.substring(window.location.href.indexOf("?") + 1) : null;
        if (queryString?.includes("#")) queryString = queryString.substring(0, queryString.indexOf("#"));
        const queryParams = queryString ? QueryString.parse(queryString) : {};

        Object.keys(queryParams).forEach((key) => {
            if (key.toLowerCase() === "adminlogin") {
                if (Array.isArray(queryParams[key])) {
                    (queryParams[key] as string[]).forEach((value) => {
                        if (StringUtils.isTruthy(value)) {
                            adminLoginOverride = true;
                        }
                    });
                } else {
                    if (StringUtils.isTruthy(queryParams[key] as string)) {
                        adminLoginOverride = true;
                    }
                }
            }
        });

        let showEmailLogin = process.env.NODE_ENV !== "production" || applicationInformation.loginConfiguration.emailPassword || (applicationInformation.loginConfiguration.emailPasswordAdminAllowed && adminLoginOverride);
        let showExternalLogin = applicationInformation.loginConfiguration.availableExternalAuthenticationProviders.length > 1;

        return (
            <div id={"login"} className={classes.root}>
                <Typography style={{ alignSelf: "center", marginBottom: "0.3125rem" }}>
                    <Trans>Log In</Trans>
                </Typography>

                {showEmailLogin && (
                    <>
                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"text"}
                            name={"email"}
                            value={email}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={loginEmailPasswordStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginEmailPasswordStatus)}
                            autoFocus={true}
                            options={
                                {
                                    placeholder: i18n._(t`Email Address`),
                                    pattern: Patterns.EMAIL,
                                    patternMessage: <Trans>Must be a valid email address.</Trans>,
                                    autoComplete: "email",
                                    showPasswordManagerExtensions: true,
                                } as TextFieldOptions
                            }
                        />

                        <FieldWrapper
                            formValidator={bcFormValidator}
                            className={classes.field}
                            type={"password"}
                            name={"password"}
                            value={password}
                            onChange={this.handleChange}
                            onKeyPress={this.handleKeyPress}
                            required={true}
                            disabled={loginEmailPasswordStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginEmailPasswordStatus)}
                            options={
                                {
                                    placeholder: i18n._(t`Password`),
                                    autoComplete: "password",
                                    showPasswordManagerExtensions: true,
                                } as TextFieldOptions
                            }
                        />

                        <Button
                            ref={this.loginEmailPasswordButtonRef}
                            className={classes.button}
                            id={"login-email"}
                            type={"primary"}
                            onClick={this.onLoginEmailPassword}
                            disabled={
                                (loginEmailPasswordStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginEmailPasswordStatus)) ||
                                (loginExternalStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginExternalStatus))
                            }
                        >
                            {loginEmailPasswordStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginEmailPasswordStatus) ? <Trans>Please Wait...</Trans> : <Trans>Log in</Trans>}
                        </Button>

                        {loginEmailPasswordStatus === LoginStatus.NOT_CONFIGURED && <Chip className={classes.chip} label={<Trans>Authentication type is not configured.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.USER_IS_LOCKED && <Chip className={classes.chip} label={<Trans>User is locked.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.UNKNOWN_USER && <Chip className={classes.chip} label={<Trans>Unknown user.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.BAD_PASSWORD && <Chip className={classes.chip} label={<Trans>Bad password. {remainingLoginAttempts} tries left.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.MUST_LOGIN_WITH_SSO && <Chip className={classes.chip} label={<Trans>User must login via SSO.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.MUST_NOT_HAVE_SSO_CREDENTIALS && <Chip className={classes.chip} label={<Trans>User must not have SSO credentials.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.RESTRICTED && <Chip className={classes.chip} label={<Trans>Access to this application is restricted.</Trans>} />}
                        {loginEmailPasswordStatus === LoginStatus.UNKNOWN_ERROR && <Chip className={classes.chip} label={<Trans>Access denied.</Trans>} />}

                        <div className={classes.forgotPassword}>
                            <Link data-id={"forgot-password"} onClick={this.props.onRecover} noWrap>
                                <Trans>Forgot Password?</Trans>
                            </Link>
                        </div>

                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "0.3125rem", marginRight: "0.3125rem" }} />
                    </>
                )}

                {!showEmailLogin && (
                    <>
                        <span style={{ alignSelf: "center", flex: "0 0 auto", width: "8rem", height: "8rem", overflow: "hidden", marginTop: "1rem" }}>
                            <ExternalLoginIcon style={{ width: "100%", height: "100%" }} />
                        </span>

                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "0.3125rem", marginRight: "0.3125rem" }} />
                    </>
                )}

                {showExternalLogin && (
                    <>
                        {applicationInformation.loginConfiguration.availableExternalAuthenticationProviders.map((externalAuthenticationProvider: ExternalAuthenticationProvider, idx: number) => {
                            // Skip the first one, which is always the default/fallback provider.
                            // This provider is used when no other providers are present and/or the SSO link is disabled (i.e. not shown) but SSO is still enabled.
                            if (idx === 0) return null;

                            return (
                                <span key={idx} style={{ flex: "0 0 auto", display: "flex", flexDirection: "column", alignItems: "stretch" }}>
                                    <Button
                                        className={classes.button}
                                        id={"login-external-" + externalAuthenticationProvider.type}
                                        type={loginExternalStatus === LoginStatus.AUTHENTICATED ? "semantic-positive-primary" : "primary"}
                                        onClick={() => this.onLoginExternal(externalAuthenticationProvider.type)}
                                        disabled={
                                            (loginEmailPasswordStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginEmailPasswordStatus)) ||
                                            (loginExternalStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginExternalStatus))
                                        }
                                    >
                                        {externalAuthenticationProvider.type === session.externalAuthenticationProvider?.type && loginExternalStatus != null && [LoginStatus.PENDING, LoginStatus.AUTHENTICATED].includes(loginExternalStatus) ? (
                                            <Trans>Please Wait...</Trans>
                                        ) : (
                                            externalAuthenticationProvider.params.label || <Trans>SSO Login</Trans>
                                        )}
                                    </Button>
                                </span>
                            );
                        })}

                        {loginExternalStatus === LoginStatus.NOT_CONFIGURED && <Chip className={classes.chip} label={<Trans>Authentication type is not configured.</Trans>} />}
                        {loginExternalStatus === LoginStatus.USER_IS_LOCKED && <Chip className={classes.chip} label={<Trans>User is locked.</Trans>} />}
                        {loginExternalStatus === LoginStatus.UNKNOWN_USER && <Chip className={classes.chip} label={<Trans>Unknown user.</Trans>} />}
                        {loginExternalStatus === LoginStatus.BAD_PASSWORD && <Chip className={classes.chip} label={<Trans>Bad password. {remainingLoginAttempts} tries left.</Trans>} />}
                        {loginExternalStatus === LoginStatus.MUST_LOGIN_WITH_SSO && <Chip className={classes.chip} label={<Trans>User must login via SSO.</Trans>} />}
                        {loginExternalStatus === LoginStatus.MUST_NOT_HAVE_SSO_CREDENTIALS && <Chip className={classes.chip} label={<Trans>User must not have SSO credentials.</Trans>} />}
                        {loginExternalStatus === LoginStatus.RESTRICTED && <Chip className={classes.chip} label={<Trans>Access to this application is restricted.</Trans>} />}
                        {loginExternalStatus === LoginStatus.UNKNOWN_ERROR && <Chip className={classes.chip} label={<Trans>Access denied.</Trans>} />}
                    </>
                )}

                {applicationInformation.selfRegistrationEnabled && (
                    <span className={classes.signup}>
                        <Typography>
                            <Trans>Don't have an account yet?</Trans>
                        </Typography>

                        <Link data-id={"join-braincloud"} onClick={this.props.onRegister} noWrap>
                            <Trans>Join brainCloud</Trans>
                        </Link>
                    </span>
                )}
            </div>
        );
    }
}

const styles = () =>
    createStyles({
        root: {
            flex: "1 1 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
        },

        field: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",
        },
        button: {
            flex: "0 0 auto",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            textTransform: "none",
        },
        chip: {
            height: "2rem",
            fontWeight: "bold",

            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "0.3125rem",
            marginRight: "0.3125rem",

            backgroundColor: "var(--chip-negative-background-color)",
            color: "var(--chip-negative-color)",
            borderColor: "inherit",
        },

        forgotPassword: {
            flex: "0 0 auto",
            alignSelf: "flex-end",

            display: "flex",
            alignItems: "center",

            margin: "0.3125rem",

            fontSize: "12px",
        },
        signup: {
            flex: "0 0 auto",
            alignSelf: "center",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",

            margin: "0.3125rem",

            fontSize: "0.75rem",

            "& .MuiTypography-root": {
                fontSize: "0.75rem",
            },

            "& > *:last-child": {
                marginLeft: "0.3125rem",
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withFormValidator()(withI18n()(withStyles(styles)(Login))));
