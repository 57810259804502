import { ReactNode } from "react";

export default interface BillingPlan {
    planCode: string;
    name: string;

    isPlusPlan: number;
    isBulkPlan: number;
    basePrice: number;
    bulkSavings: number;

    displayOrder?: number;

    paymentMethodRequired?: boolean;

    includesDeepData?: boolean;
    includesHosting?: boolean;
    includesEnterprise?: boolean;
    includesRTTCCx?: boolean;
    includesRTT?: boolean;

    description?: string;
    displayLine1?: string;
    displayLine2?: string;

    maxAccounts?: number;
    maxDau?: number;
    baseApiIncluded?: number;

    isLive?: boolean;

    generation?: number;

    isUserSelectable: boolean;
    isSuperSelectable: boolean;
}
export interface FeaturesList {
    key: "coreFeatures" | "includesEnterprise" | "includesRTT" | "includesHosting" | "maxDau" | "maxAccounts" | "baseApiIncluded" | "includesDeepData";
    label: ReactNode;
}

export const processBillingPlan = (data: any): BillingPlan => {
    const includesDeepData = data.customFields ? data.customFields.find((item: any) => item.key === "IncludesDeepData")?.defaultValue : 0;
    const isUserSelectable = data.customFields ? data.customFields.find((item: any) => item.key === "IsUserSelectable")?.defaultValue : 0;
    const isSuperSelectable = data.customFields ? data.customFields.find((item: any) => item.key === "isSuperSelectable")?.defaultValue : 0;

    return {
        planCode: data.planCode != null ? data.planCode : data.subscriptionType != null ? data.subscriptionType : "",
        name: data.name,

        isPlusPlan: data.customFields ? data.customFields.find((item: any) => item.key === "isPlusPlan")?.defaultValue : 0,
        isBulkPlan: data.customFields ? data.customFields.find((item: any) => item.key === "isBulkPlan")?.defaultValue : 0,
        basePrice: data.customFields ? data.customFields.find((item: any) => item.key === "basePrice")?.defaultValue || 0 : 0,
        bulkSavings: data.customFields ? data.customFields.find((item: any) => item.key === "bulkSavings")?.defaultValue : 0,

        displayOrder: data.displayOrder != null ? data.displayOrder : undefined,

        paymentMethodRequired: data.PaymentMethodRequired != null ? Boolean(data.PaymentMethodRequired) : undefined,

        isUserSelectable: isUserSelectable != null ? Boolean(isUserSelectable) : false,
        isSuperSelectable: isSuperSelectable != null ? Boolean(isSuperSelectable) : false,

        includesDeepData: includesDeepData != null ? Boolean(includesDeepData) : undefined,
        includesHosting: data.includesHosting != null ? Boolean(data.includesHosting) : data.IncludesHosting != null ? Boolean(data.IncludesHosting) : undefined,
        includesEnterprise: data.includesEnterprise != null ? Boolean(data.includesEnterprise) : data.IncludesEnterprise != null ? Boolean(data.IncludesEnterprise) : undefined,
        includesRTTCCx: data.includesRTTCCx != null ? Boolean(data.includesRTTCCx) : data.IncludesRTTCCx != null ? Boolean(data.IncludesRTTCCx) : undefined,
        includesRTT: data.includesRTT != null ? Boolean(data.includesRTT) : data.IncludesRTT != null ? Boolean(data.IncludesRTT) : undefined,

        description: data.description != null ? data.description : undefined,
        displayLine1: data.displayLine1 != null ? data.displayLine1 : undefined,
        displayLine2: data.displayLine2 != null ? data.displayLine2 : undefined,

        maxAccounts: data.maxAccounts != null ? Number.parseInt(data.maxAccounts) : undefined,
        maxDau: data.maxDau != null ? Number.parseInt(data.maxDau) : undefined,
        baseApiIncluded: data.customFields ? data.customFields.find((item: any) => item.key === "baseapiincluded")?.defaultValue : undefined,

        isLive: data.IsLive != null ? Boolean(data.IsLive) : undefined,

        generation: data.generation != null ? Number.parseInt(data.generation) : data.gen != null ? Number.parseInt(data.gen) : undefined,
    };
};

interface FeatureStatus {
    enabled: boolean;
    dataExists: boolean;
    dataDetails?: string[];
}

const processFeatureStatus = (data: any): FeatureStatus => {
    return {
        enabled: data.enabled,
        dataExists: data.dataExists,
        dataDetails: data.dataDetails,
    };
};

export interface AppPlanStatus {
    allowBasicPlan: boolean;
    rtt: FeatureStatus;
    presence: FeatureStatus;
    chat: FeatureStatus;
    messaging: FeatureStatus;
    lobby: FeatureStatus;
    servers: FeatureStatus;
    customEntities: FeatureStatus;
}

export const processAppPlanStatus = (data: any): AppPlanStatus => {
    return {
        allowBasicPlan: data.allowBasicPlan,
        rtt: processFeatureStatus(data.rtt),
        presence: processFeatureStatus(data.presence),
        chat: processFeatureStatus(data.chat),
        messaging: processFeatureStatus(data.messaging),
        lobby: processFeatureStatus(data.lobby),
        servers: processFeatureStatus(data.servers),
        customEntities: processFeatureStatus(data.customEntities),
    };
};
