import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";

import availableLanguages from "assets/data/availableLanguages";

export interface GameLanguages {
    defaultLanguageCode: string;
    supportedLanguageCodes: string[];
}

export interface Language {
    code: string;
    label: React.ReactNode;
    labelAsString: (i18n: I18n) => string;
}

export const getLanguageByCode = (code?: string | null): Language => {
    const language = availableLanguages.find((item) => code != null && item.code === code.toLowerCase()) || null;

    return (
        language || {
            code: code || "",
            label: <Trans>Unsupported Language Code ({code || ""})</Trans>,
            labelAsString: (i18n: I18n) => i18n._(t`Unsupported Language Code (${code || ""})`),
        }
    );
};

export const getMultipleLanguagesByCode = (codes?: (string | null)[] | null): Language[] => {
    return codes?.map((code) => getLanguageByCode(code)) || [];
};
