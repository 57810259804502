import SampleData from "types/common/SampleData";

export interface SampleDataAction {
    type: "SET_SAMPLE_DATA";
    payload: SampleData;
}

export const SET_SAMPLE_DATA = (sampleData: SampleData): SampleDataAction => {
    return {
        type: "SET_SAMPLE_DATA",
        payload: sampleData,
    };
};
