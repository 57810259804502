import { ReactNode } from "react";

import NumberFormat from "react-number-format";

import { getCurrencyInfobyCode } from "types/models/CurrencyInfo";

export default abstract class NumberFormatter {
    public static formatInteger(value?: number | null): ReactNode {
        return value != null ? <NumberFormat value={value} displayType={"text"} thousandSeparator={true} decimalScale={0} fixedDecimalScale={true} /> : null;
    }
    public static formatFloat(value?: number | null, decimalScale?: number | null, fixedDecimalScale?: boolean | null): ReactNode {
        return value != null ? (
            <NumberFormat
                value={value}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={decimalScale != null ? decimalScale : decimalScale === null ? undefined : 3}
                fixedDecimalScale={fixedDecimalScale != null ? fixedDecimalScale : fixedDecimalScale === null ? undefined : true}
            />
        ) : null;
    }
    public static formatCurrency(value?: number | null, currencyCode?: string | null, fixedDecimalScale?: boolean | null): ReactNode {
        const currencyDetails = getCurrencyInfobyCode(currencyCode ? currencyCode : "USD");

        return value != null ? (
            <NumberFormat
                value={value}
                displayType={"text"}
                prefix={currencyDetails?.symbolOnLeft === true ? (currencyDetails?.symbol != null ? currencyDetails?.symbol : "$") : undefined}
                suffix={currencyDetails?.symbolOnLeft === false ? (currencyDetails?.symbol != null ? currencyDetails?.symbol : "$") : undefined}
                thousandSeparator={currencyDetails?.thousandsSeparator != null ? currencyDetails?.thousandsSeparator : true}
                decimalSeparator={currencyDetails?.decimalSeparator ? currencyDetails?.decimalSeparator : undefined}
                decimalScale={currencyDetails?.decimalDigits != null ? currencyDetails?.decimalDigits : 2}
                fixedDecimalScale={fixedDecimalScale != null ? fixedDecimalScale : fixedDecimalScale === null ? undefined : true}
            />
        ) : null;
    }
}
