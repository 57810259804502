import React from "react";
import { withStyles } from "tss-react/mui";
import { Trans } from "@lingui/macro";
import classnames from "classnames";

import { Theme, Typography } from "@mui/material";
import Tooltip from "../Tooltip";
import IconButton from "../button/IconButton";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import ClipboardUtils from "utils/Clipboard";

interface OWN_PROPS {
    style?: React.CSSProperties;
    id: string;
    value?: string | null;
    showCopyIcon?: boolean;
    customValueToCopy?: string | null;
    customTooltip?: React.ReactNode;
}

interface PROPS extends OWN_PROPS {
    className?: string;
    classes?: Partial<Record<keyof ReturnType<typeof styles>, string>>;
}

interface STATE {
    truncatedText: string | null;
}

class EllipsisInCenter extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = { truncatedText: "" };

    private observer: ResizeObserver | null = null;
    private resizerDelayTimer: number = 0;

    private spanRef = React.createRef<HTMLDivElement>();
    private textRef = React.createRef<HTMLDivElement>();
    private iconRef = React.createRef<HTMLDivElement>();

    private _isMounted: boolean = false;

    componentDidMount(): void {
        this._isMounted = true;

        if (this.spanRef.current) {
            this.observer = new ResizeObserver(() => {
                if (this.resizerDelayTimer) {
                    window.clearTimeout(this.resizerDelayTimer);
                }

                this.resizerDelayTimer = window.setTimeout(() => {
                    this.handleResize();
                }, 100);
            });

            this.observer.observe(this.spanRef.current);
        }
    }

    componentDidUpdate(prevProps: Readonly<PROPS>): void {
        if (this.props.value !== prevProps.value) {
            this.handleResize();
        }
    }

    componentWillUnmount(): void {
        this._isMounted = false;

        this.observer?.disconnect();
    }

    handleResize = () => {
        const { value } = this.props;
        const { current: spanRef } = this.spanRef;
        const { current: textRef } = this.textRef;
        const { current: iconRef } = this.iconRef;

        if (!value) {
            this.setState({ truncatedText: "" });

            return;
        }

        if (spanRef && textRef) {
            // Calculate available width considering the icon width and some extra space
            const availableWidth = spanRef.offsetWidth - (iconRef ? iconRef.offsetWidth : 0);

            // Get computed styles from the textRef element
            const labelFontSize = window.getComputedStyle(textRef).fontSize;
            const labelFontFamily = window.getComputedStyle(textRef).fontFamily;
            const labelFontWeight = window.getComputedStyle(textRef).fontWeight;
            const labelLetterSpacing = window.getComputedStyle(textRef).letterSpacing;

            // Create a temporary span element to measure the width of the text
            const tempSpan = document.createElement("span");
            tempSpan.textContent = value;
            tempSpan.style.fontSize = labelFontSize;
            tempSpan.style.fontFamily = labelFontFamily;
            tempSpan.style.fontWeight = labelFontWeight;
            tempSpan.style.letterSpacing = labelLetterSpacing;
            tempSpan.style.visibility = "hidden"; // Make sure the element is not visible
            document.body.appendChild(tempSpan);

            // Get the width of the temporary span element
            const tempSpanWidth = tempSpan.offsetWidth;

            // Calculate the maximum number of characters that can fit in the width
            const avgCharWidth = Math.ceil(tempSpanWidth / value.length);
            const characterCount = Math.floor(availableWidth / avgCharWidth) - 2;

            let truncatedText = value;

            // Check if the available width is less than the width of the text
            if (availableWidth <= tempSpanWidth) {
                // Truncate the text if it exceeds the available width
                const startString = value.slice(0, characterCount / 2);
                const endString = value.substring(value.length - (characterCount - startString.length));
                truncatedText = startString + "..." + endString;
            }

            // Remove the temporary span element
            document.body.removeChild(tempSpan);

            // Update state with the truncated text if it has changed
            if (this._isMounted && this.state.truncatedText !== truncatedText) {
                this.setState({ truncatedText });
            }
        }
    };

    render() {
        const { style, value, className, showCopyIcon, id, customValueToCopy, customTooltip } = this.props;
        const { truncatedText } = this.state;

        const classes = withStyles.getClasses(this.props);

        return (
            <span id={id} ref={this.spanRef} className={classnames(classes.root, showCopyIcon ? classes.copyOnHover : null, className)} style={style}>
                <Tooltip alwaysShow={customTooltip != null || value !== truncatedText} arrow title={customTooltip || value}>
                    <Typography ref={this.textRef} noWrap>
                        {truncatedText}
                    </Typography>
                </Tooltip>

                {showCopyIcon && (customValueToCopy || value) && (
                    <Tooltip alwaysShow arrow title={<Trans>Copy To Clipboard</Trans>}>
                        <IconButton id={"copy-to-clipboard"} ref={this.iconRef} onClick={() => ClipboardUtils.writeText(customValueToCopy || value)}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </span>
        );
    }
}

const styles = (_theme: Theme, _props: PROPS) =>
    ({
        root: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflow: "hidden",

            "& > .MuiTypography-root": {
                fontSize: "inherit",
                fontWeight: "inherit",
                textOverflow: "unset",
            },

            "& > .MuiIconButton-root": {
                flex: "0 0 auto",
                margin: "0 0 0 0.25em",
                fontSize: "0.85em",
                color: "var(--button-secondary-color)",
            },
        },
        copyOnHover: {
            "& > .MuiIconButton-root": {
                opacity: 0,
                transition: "opacity 0.2s ease",
            },
            "&:hover > .MuiIconButton-root": {
                opacity: 1,
            },
        },
    } as const);

export default withStyles(EllipsisInCenter, styles);
