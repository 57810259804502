// Import libraries.
import React from "react";
import { Theme, Typography } from "@mui/material";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n, withI18nProps } from "@lingui/react";
import { I18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { WithWidthProps, withWidth } from "framework/width";
import { connect } from "react-redux";

// Import types.
import PortalRouteDefinition from "types/common/PortalRouteDefinition";
import ThemeConfiguration from "types/common/ThemeConfiguration";
import SampleData from "types/common/SampleData";
import PortalState from "types/store";
import Session from "types/common/Session";
import AppInfo from "types/models/AppInfo";
import User from "types/common/User";

// Import redux actions.
import { SET_FAVORITES } from "store/actions/favorites";
import { SET_SAMPLE_DATA } from "store/actions/sampleData";

// Import components.
import { Popover } from "@mui/material";
import Tooltip from "components/common/Tooltip";
import InfoBox from "components/common/screen/InfoBox";
import IconButton from "components/common/button/IconButton";
import FieldWrapper from "components/common/form/FieldWrapper";
import Title from "components/common/screen/Title";
import PlayerLiveLock from "../PlayerLiveLock";

// Import icons.
import InfoOutlineIcon from "@mui/icons-material/InfoOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ChevronUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ChevronDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpIcon from "@mui/icons-material/Help";

// Import screen data collection.
import { getScreenDataForId } from "assets/data/screens";

// Import utilities.
import PortalRouteUtils from "utils/PortalRoutes";
import CookieConsentUtils, { CookieConsentLevel } from "utils/CookieConsent";
import FavoriteUtils from "utils/Favorites";

interface OWN_PROPS {
    definitions: PortalRouteDefinition[];

    open: boolean;
    onToggleInfoOpen: () => void;

    fullscreen: boolean;
    onToggleFullscreen: () => void;

    bannerOpen: boolean;
    bannerMode: "team" | "app" | "player";
}

interface DISPATCH_PROPS {
    setFavorites: (favorites: string[]) => void;
    setSampleData: (showSampleData: SampleData) => void;
}
interface STATE_PROPS {
    session: Session;
    availableApps: AppInfo[];
    currentUser: User | null;
    favorites: string[];
    sampleData: SampleData;
    themeConfiguration: ThemeConfiguration;
}
interface PROPS extends STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, WithStyles<typeof styles>, RouteComponentProps, withI18nProps, WithWidthProps {}

interface STATE {
    maxHeight: string | number;
    anchorEl: any;
    isSampleDataScreen: boolean;
}
const mapStateToProps = (state: PortalState): STATE_PROPS => {
    return {
        session: state.session,
        availableApps: state.availableApps,
        currentUser: state.currentUser,
        favorites: state.favorites,
        sampleData: state.sampleData,
        themeConfiguration: state.themeConfiguration,
    };
};

const mapDispatchToProps = (dispatch: Function): DISPATCH_PROPS => {
    return {
        setFavorites: (favorites: string[]) => dispatch(SET_FAVORITES(favorites)),
        setSampleData: (sampleData: SampleData) => dispatch(SET_SAMPLE_DATA(sampleData)),
    };
};

class Info extends React.PureComponent<PROPS, STATE> {
    state: Readonly<STATE> = {
        maxHeight: "unset",
        anchorEl: null,
        isSampleDataScreen: false,
    };
    private rootRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);

        if (this.rootRef.current && this.state.maxHeight === "unset") {
            this.setState({ maxHeight: this.rootRef.current.scrollHeight });
        }

        this.setIsSampleDataScreen();
    }

    componentDidUpdate(prevProps: PROPS, prevState: STATE) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setIsSampleDataScreen();

            this.setState({ maxHeight: "unset" });

            let currentPath = this.props.location.pathname;

            const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(this.props.definitions, currentPath);

            if (!currentRoute?.permitFullscreen && this.props.fullscreen) {
                this.props.onToggleFullscreen();
            }
        } else {
            if (this.rootRef.current && this.state.maxHeight === "unset") {
                this.setState({ maxHeight: this.rootRef.current.scrollHeight || "unset" });
            }
        }

        if (prevProps.sampleData !== this.props.sampleData) {
            this.setIsSampleDataScreen();
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        const { maxHeight } = this.state;

        if (this.rootRef.current && maxHeight !== "unset" && this.rootRef.current.scrollHeight !== maxHeight) {
            this.setState({ anchorEl: null, maxHeight: this.rootRef.current.scrollHeight || "unset" });
        }
    };

    openInfoBox = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    closeInfoBox = () => {
        this.setState({ anchorEl: null });
    };

    toggleFavorite = () => {
        const { location, definitions, currentUser, favorites } = this.props;

        let currentPath = PortalRouteUtils.sanitizeRoutePath(location.pathname);

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = PortalRouteUtils.sanitizeRoutePath(currentRoute.originalPath);
        }

        if (currentUser) {
            const updatedFavorites = [...favorites];

            if (updatedFavorites.includes(currentPath)) {
                updatedFavorites.splice(updatedFavorites.indexOf(currentPath), 1);
            } else {
                updatedFavorites.push(currentPath);
            }

            FavoriteUtils.setFavorites(currentUser.profileId, updatedFavorites);

            this.props.setFavorites(updatedFavorites);
        }
    };

    toggleFullscreen = (event: React.MouseEvent<HTMLElement>) => {
        event.currentTarget.blur();

        this.props.onToggleFullscreen();
    };

    getCSSValue = (name: string) => {
        const { themeConfiguration, session } = this.props;

        const activeTheme = themeConfiguration.activeTheme;
        const themeMode = session.themeMode;

        return activeTheme?.[themeMode]?.cssVariables[name] as string;
    };

    setIsSampleDataScreen = () => {
        const { sampleData } = this.props;

        const isSampleDataScreen = sampleData?.showSampleData;

        if (this.state.isSampleDataScreen !== isSampleDataScreen) {
            this.setState({ isSampleDataScreen });
        }
    };

    toggleSampleDataSwitch = () => {
        const { sampleData } = this.props;

        this.props.setSampleData({ ...sampleData, showSampleData: !sampleData?.showSampleData });
    };

    render() {
        const { width, isWidthDown, i18n, classes, location, definitions, open, fullscreen, bannerOpen, bannerMode, session, availableApps, favorites, sampleData } = this.props;
        const { maxHeight, anchorEl, isSampleDataScreen } = this.state;

        let currentPath = location.pathname;

        const currentRoute = PortalRouteUtils.getPortalRouteDefinitionForPath(definitions, currentPath);

        const isFavorite = favorites.includes(currentPath);
        const favoriteEnabled = CookieConsentUtils.isLevelPermitted(CookieConsentLevel.FUNCTIONALITY);

        // If the current route has an original path then it represents a favorite route.
        // In this case we use the original path instead of the location path (since favorite paths are special/index-based).
        if (currentRoute?.originalPath) {
            currentPath = currentRoute.originalPath;
        }

        let screenData = getScreenDataForId(currentPath);

        if (!screenData) {
            screenData = {
                id: currentPath,
                title: (i18n: I18n) => i18n._(t`Loading...`),
            };
        }

        // Determine whether the screen info should be shown in the "mobile" view.
        const minimalScreenInfo = width && isWidthDown("sm", width);

        // Determine the currently selected app.
        const targetApp = availableApps.find((item) => item.appId === session.appId) || null;

        // Determine the current navigation context.
        const context = location.pathname.startsWith("/super") ? "super" : location.pathname.startsWith("/team") ? "team" : location.pathname.startsWith("/app") ? "app" : location.pathname.startsWith("/user") ? "user" : null;

        const showSampleDataSwitch = sampleData.showSwitch && location.pathname.startsWith("/app/reports/analytics") && !location.pathname.includes("analytics/core");

        const backgroundColor = isSampleDataScreen ? this.getCSSValue(`portal-color-orange`).replace(/[^,]+(?=\))/, "0.35") : "inherit";

        return (
            <div id={"screen-info"} className={classes.root}>
                {screenData.title && (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {favoriteEnabled && (
                                <Tooltip alwaysShow arrow title={isFavorite ? <Trans>Remove from favorites</Trans> : <Trans>Add to favorites</Trans>}>
                                    <IconButton id="toggle-favorite" onClick={this.toggleFavorite} style={{ marginLeft: "0.5em" }}>
                                        {isFavorite ? <StarIcon /> : <StarOutlineIcon />}
                                    </IconButton>
                                </Tooltip>
                            )}
                            <div>
                                <Title label={screenData.title(i18n)}>
                                    {isSampleDataScreen && (
                                        <Typography variant="h6" style={{ marginLeft: "0.5em", color: "var(--portal-color-orange, inherit)" }}>
                                            <Trans>(Sample Data)</Trans>
                                        </Typography>
                                    )}

                                    {currentRoute?.permitFullscreen && (
                                        <Tooltip alwaysShow arrow title={fullscreen ? <Trans>Exit Fullscreen</Trans> : <Trans>Enter Fullscreen</Trans>}>
                                            <IconButton id="toggle-fullscreen" onClick={this.toggleFullscreen} style={{ marginLeft: "0.5em" }}>
                                                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {screenData.infobox?.learnMore && (
                                        <Tooltip alwaysShow arrow title={<Trans>Screen Help</Trans>}>
                                            <IconButton
                                                id={"screen-help"}
                                                onClick={() => {
                                                    if (screenData?.infobox?.learnMore) window.open(screenData.infobox.learnMore);
                                                }}
                                                style={{ marginLeft: "0.5em" }}
                                            >
                                                <HelpIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    {context === "user" && !location.pathname.startsWith("/user/user-browser") && targetApp?.isLive && (!bannerOpen || bannerMode !== "player") && (
                                        <PlayerLiveLock style={{ flex: "1 1 auto", height: "1.5em", minWidth: "8em", maxWidth: "8em", marginLeft: "0.5em" }} thumbAndTextColor={"var(--secondary-background-color)"} />
                                    )}
                                </Title>
                            </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", marginRight: "0.5em" }}>
                            {showSampleDataSwitch && (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <FieldWrapper
                                        type={"switch"}
                                        name={"sampleDataSwitch"}
                                        label={<Trans>Actual</Trans>}
                                        labelStyle={{ flex: "1 1 auto", flexWrap: "nowrap" }}
                                        controlStyle={{ flex: "0 0 auto" }}
                                        className={isSampleDataScreen ? classes.switchOn : classes.switchOff}
                                        value={isSampleDataScreen}
                                        onChange={this.toggleSampleDataSwitch}
                                    />

                                    <Typography>
                                        <Trans>Sample</Trans>
                                    </Typography>
                                </div>
                            )}

                            {minimalScreenInfo && screenData.infobox && (
                                <IconButton id="infobox" onClick={this.openInfoBox}>
                                    <InfoOutlineIcon />
                                </IconButton>
                            )}

                            {!minimalScreenInfo && (
                                <Tooltip alwaysShow arrow title={open ? <Trans>Hide screen information</Trans> : <Trans>Show screen information</Trans>}>
                                    <IconButton id="toggle-screen-info" onClick={this.props.onToggleInfoOpen} style={{ marginRight: !currentRoute?.permitFullscreen ? "0.5em" : undefined }}>
                                        {open ? <ChevronUpIcon style={{ pointerEvents: "none" }} /> : <ChevronDownIcon style={{ pointerEvents: "none" }} />}
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                )}

                <div ref={this.rootRef} style={{ maxHeight: open ? maxHeight : 0, backgroundColor, transition: "max-height 0.2s linear, border-bottom-width 0.2s linear" }}>
                    {screenData.infobox && !minimalScreenInfo && <InfoBox text={screenData.infobox.text} />}
                </div>

                {screenData.infobox && minimalScreenInfo && anchorEl && (
                    <Popover
                        id={"learn-more-pop-over"}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={this.closeInfoBox}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <InfoBox hideBorder={true} style={{ flexDirection: "column" }} text={screenData.infobox.text} />
                    </Popover>
                )}
            </div>
        );
    }
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flex: "0 0 auto",

            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",

            backgroundColor: "inherit",
            color: "inherit",
            borderColor: "inherit",

            position: "relative",
            overflow: "hidden",
        },
        title: {
            flex: "1 1 auto",
            fontSize: "1.2em",
            fontWeight: "bold",
        },
        switchOn: {
            "& .MuiSwitch-root": {
                "& .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: "var(--primary-background-color) !important",
                    borderColor: "var(--portal-color-orange) !important",
                },
            },
        },
        switchOff: {
            "& .MuiSwitch-root": {
                "& .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: "var(--primary-background-color) !important",
                    borderColor: "var(--portal-color-grey) !important",
                },
            },
        },
    });

export default connect<STATE_PROPS, DISPATCH_PROPS, OWN_PROPS, PortalState>(mapStateToProps, mapDispatchToProps)(withRouter(withWidth()(withI18n()(withStyles(styles)(Info)))));
