import AuthType from "types/enums/AuthType";

import ExternalAuthenticationProvider from "types/common/ExternalAuthenticationProvider";
import PlayerSummary from "types/models/PlayerSummaryInfo";

export const sessionFromString = (sessionData?: string | null): Session => {
    const session: Session = {
        authType: null,
        email: null,
        isAuthenticated: false,
        portalContext: "default",
        themeMode: "light",
        sessionLength: null,
        sessionTimeout: null,
        sessionKeepAlive: null,
        sessionSynchronizationTimeout: null,
    };

    try {
        const json = sessionData ? JSON.parse(sessionData) : null;

        if (json) {
            if (json.authType != null) session.authType = json.authType;
            if (json.externalAuthType != null) session.externalAuthType = json.externalAuthType;
            if (json.autoAttemptExternalAuth != null) session.autoAttemptExternalAuth = json.autoAttemptExternalAuth;
            if (json.autoCompleteCompanySelection != null) session.autoCompleteCompanySelection = json.autoCompleteCompanySelection;
            if (json.email != null) session.email = json.email.toLowerCase();
            if (json.isAuthenticated != null) session.isAuthenticated = json.isAuthenticated;
            if (json.isSuper != null) session.isSuper = json.isSuper;
            if (json.isTeamAdmin != null) session.isTeamAdmin = json.isTeamAdmin;
            if (json.requiresTermsOfServiceAcceptance != null) session.requiresTermsOfServiceAcceptance = json.requiresTermsOfServiceAcceptance;
            if (json.companyId != null) session.companyId = json.companyId;
            if (json.companyIdAlias != null) session.companyIdAlias = json.companyIdAlias;
            if (json.appId != null) session.appId = json.appId;
            if (json.appCurrency != null) session.appCurrency = json.appCurrency;
            if (json.isLiveLocked != null) session.isLiveLocked = json.isLiveLocked;
            if (json.disableLiveLock != null) session.disableLiveLock = json.disableLiveLock;
            if (json.playerId != null) session.playerId = json.playerId;
            if (json.portalContext != null && json.portalContext === "default") session.portalContext = json.portalContext;
            // if (json.themeMode != null) session.themeMode = json.themeMode;
            if (json.sessionLength != null) session.sessionLength = json.sessionLength;
            if (json.sessionTimeout != null) session.sessionTimeout = json.sessionTimeout;
            if (json.sessionKeepAlive != null) session.sessionKeepAlive = json.sessionKeepAlive;
            if (json.sessionSynchronizationTimeout != null) session.sessionSynchronizationTimeout = json.sessionSynchronizationTimeout;
            if (json.externalAuthenticationProvider != null) session.externalAuthenticationProvider = json.externalAuthenticationProvider;
            if (json.developerMode != null) session.developerMode = json.developerMode;
            if (json.forceReselectionOfCompanyId != null) session.forceReselectionOfCompanyId = json.forceReselectionOfCompanyId;
        }
    } catch (error) {
        console.warn("Unable to parse session", error, sessionData);
    }

    return session;
};

export const sessionToString = (session?: Session | null, pretty?: boolean): string => {
    if (session) {
        return pretty ? JSON.stringify(session, null, 4) : JSON.stringify(session);
    } else {
        return "";
    }
};

export const resetSession = (session: Session) => {
    session.authType = null;
    delete session.externalAuthType;
    delete session.autoAttemptExternalAuth;
    delete session.autoCompleteCompanySelection;
    session.email = null;
    session.isAuthenticated = false;
    delete session.isSuper;
    delete session.isTeamAdmin;
    delete session.requiresTermsOfServiceAcceptance;
    delete session.companyId;
    delete session.companyIdAlias;
    delete session.appId;
    delete session.appCurrency;
    delete session.isLiveLocked;
    delete session.disableLiveLock;
    delete session.playerId;
    delete session.isPlayerLocked;
    delete session.playerSummary;
    session.sessionLength = null;
    session.sessionTimeout = null;
    session.sessionKeepAlive = null;
    session.sessionSynchronizationTimeout = null;
    delete session.externalAuthenticationProvider;
    delete session.developerMode;
    delete session.forceReselectionOfCompanyId;
};

export default interface Session {
    authType: AuthType | null;
    externalAuthType?: string | null;
    autoAttemptExternalAuth?: boolean;
    autoCompleteCompanySelection?: boolean;
    email: string | null;
    isAuthenticated: boolean;
    isSuper?: boolean;
    isTeamAdmin?: boolean;
    requiresTermsOfServiceAcceptance?: boolean;
    companyId?: string | null;
    companyIdAlias?: string | null;
    appId?: string | null;
    appCurrency?: string | null;
    isLiveLocked?: boolean;
    disableLiveLock?: boolean;
    playerId?: string | null;
    isPlayerLocked?: boolean;
    playerSummary?: PlayerSummary | null;
    portalContext: "default";
    themeMode: "light" | "dark";
    sessionLength: number | null;
    sessionTimeout: number | null;
    sessionKeepAlive: number | null;
    sessionSynchronizationTimeout: number | null;
    externalAuthenticationProvider?: ExternalAuthenticationProvider;
    developerMode?: boolean;
    forceReselectionOfCompanyId?: boolean;
}
